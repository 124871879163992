import {IUserStore} from './utils/types'
import Keycloak from 'keycloak-js'
import {autorun, IReactionDisposer, makeAutoObservable} from 'mobx'
import {IPatientService} from '../service/utils/types'
import {ConfigTypes} from '../utils/enums'
import {AxiosResponse} from 'axios'
import {isObject, isResponseSuccessful} from '../utils/helper'
import {AnyObj, ChangePassDto, ProfileDto} from '../utils/types'
import {TOKEN_MIN_VALIDITY} from '../utils/constants'

class UserStore implements IUserStore {
    keycloak: Keycloak.KeycloakInstance | null = null
    userProfile: ProfileDto | null = null // user data from server
    userConfigs: Map<ConfigTypes, any> | null | undefined = null /*null = initial State; undefined = failed to get the configs*/

    autorunCleaners: IReactionDisposer[] = []
    patientService: IPatientService

    constructor(patientService: IPatientService) {
        makeAutoObservable(this, {
            getUserConfigsServer: false,
            getUserDataServer: false
        })
        this.patientService = patientService
    }

    initialize(): void {
        const disposerKeycloakUser = autorun(this.getUserDataServer)
        const disposerUserConfigs = autorun(this.getUserConfigsServer)
        this.autorunCleaners = [disposerKeycloakUser, disposerUserConfigs]
    }

    async updateProfile(profile: ProfileDto): Promise<boolean> {
        const response: AxiosResponse<void> = await this.patientService.updateProfile(profile)
        if (!isResponseSuccessful(response)) {
            console.error('Failed to update profile')
            return Promise.resolve(false)
        }
        await this.getUserDataServer()
        return Promise.resolve(true)
    }

    async changePassword(changePass: ChangePassDto): Promise<boolean> {
        const response: AxiosResponse<void> = await this.patientService.changePassword(changePass)
        if (!isResponseSuccessful(response)) {
            console.error('Failed to change password')
            return Promise.resolve(false)
        }
        this.logout()
        return Promise.resolve(true)
    }

    /**
     * Update the access token if its validity time in seconds is less than TOKEN_MIN_VALIDITY.
     * The new keycloak instance will be persisted in the store.
     */
    async refreshAccessToken(): Promise<void> {
        await this.keycloak?.updateToken(TOKEN_MIN_VALIDITY).then((updated) => {
            if (updated) {
                this.setKeycloak(this.keycloak)
            }
        }).catch(error => {
            console.error('Failed to refresh token because ', error)
        })
    }

    async logout() {
        this.keycloak?.logout()
    }

    get getUserLoggedName() {
        return this.userProfile ? this.userProfile.firstName + ' ' + this.userProfile.lastName : ''
    }

    get getTherapyNameList(): string[] {
        return this.userConfigs?.get(ConfigTypes.THERAPY_NAME) || []
    }

    get getFederalStates(): string[] {
        return this.userConfigs?.get(ConfigTypes.FEDERAL_STATE) || []
    }

    get getClientTitlesList(): string[] {
        return (this.userConfigs?.get(ConfigTypes.TITLE) as string[]) || []
    }

    get getUnitMeasure(): AnyObj {
        return (this.userConfigs?.get(ConfigTypes.UNIT_MEASURE) as AnyObj) || {}
    }

    get getFormOfAddress(): string[] {
        return this.userConfigs?.get(ConfigTypes.FORM_OF_ADDRESS) || []
    }

    get getGender(): AnyObj {
        return this.userConfigs?.get(ConfigTypes.GENDER) || []
    }

    get getMaxWeight(): number | undefined {
        return this.userConfigs?.get(ConfigTypes.MAX_WEIGHT) || undefined
    }

    get getTinoDocUrl(): string | undefined {
        return this.userConfigs?.get(ConfigTypes.TINO_DOC_URL) || undefined
    }

    get isDoctor(): boolean {
        if (!this.userProfile) {
            return false
        }
        return this.userProfile.doctor
    }

    // setters
    setKeycloak(instance: Keycloak.KeycloakInstance | null) {
        this.keycloak = instance
    }

    setUserConfigs(configs: Map<any, string[]> | null | undefined) {
        this.userConfigs = configs
    }

    setUserProfile(user: ProfileDto | null) {
        this.userProfile = user
    }

    /**
     * Used to get i18n text based on i18n configs key.
     * @param configType the config type
     * @param key the value of the key
     */
    getI18nText(configType: ConfigTypes, key: string): string {
        if (!this.userConfigs) {
            return key
        }
        const i18nValues = this.userConfigs.get(configType)
        if (isObject(i18nValues)) {
            return i18nValues[key] || key
        }
        return key
    }

    // get data from server
    getUserConfigsServer = async () => {
        if (!this.keycloak) {
            return
        }
        const response: AxiosResponse<Record<string, string[]>> = await this.patientService.getUserConfigs()
        if (!isResponseSuccessful(response)) {
            console.error('Failed to get user configs')
            this.setUserConfigs(undefined)
            return
        }
        this.setUserConfigs(new Map(Object.entries(response.data)))
    }

    getUserDataServer = async () => {
        if (!this.keycloak) {
            return
        }

        const response: AxiosResponse<ProfileDto> = await this.patientService.getUserProfile()
        if (!isResponseSuccessful(response)) {
            console.error('Failed to get user configs')
            this.setUserProfile(null)
            return
        }
        this.setUserProfile(response.data)
    }

    async cleanup() {
        this.keycloak = null
        this.autorunCleaners.forEach(disposer => disposer())
    }
}

export default UserStore
