import {AxiosResponse} from 'axios'
import {AnyObj, DoctorSearchEntry, I18nEnumPrefix, OptionItem} from './types'
import {i18n} from '../i18n'
import {useMemo} from 'react'
import {StorageKey} from './enums'
import {toNumber} from './observation_helper'
import {PARTIAL_SUCCESS_RESPONSE_STATUS_CODE} from './constants'

// 24-hour format with mandatory leading 0
export const TIME_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

export const NUMBER_INPUT_REGEX = /^-?\d*([,.]\d*)?$/

/**
 * This method check if a given value match a given regex test
 * @param value
 * @param regex
 */
export function matchRegex(value: string, regex: RegExp): boolean {
    if (value && regex) {
        return new RegExp(regex).test(value)
    }
    return false
}

/**
 * This method is used to validate a given value
 * @param date
 */
export function isValidDate(date: Date | undefined): boolean {
    if (!date) {
        return false
    }
    return typeof date.getTime !== 'undefined' && !isNaN(date.getTime())
}

/**
 * function used to check if the input is number
 * I know I could used if(!input) but this returns true if value is 0
 * @param input
 */
export function isNumber(input: any): boolean {
    return input !== undefined && input !== null && input !== "" && !isNaN(Number(input))
}

export function isBoolean(input: any): boolean {
    return input !== undefined && input !== null && input !== "" && typeof input === 'boolean'
}

/**
 * function used to transform all string dates from a given object into Date objects
 * @param o
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformDateValues(o: any): any {
    if (o) {
        for (const i of Object.keys(o)) {
            if (o[i] !== null && typeof o[i] === 'object') {
                transformDateValues(o[i])
            }
            if (typeof o[i] !== 'object' && typeof o[i] === 'string') {
                if (matchRegex(o[i], ISO_8601) || (matchRegex(o[i], ISO_8601_DATE) && isValidDate(new Date(o[i])))) {
                    o[i] = new Date(o[i])
                }
            }
        }
    }
    return o
}

export function fromJson(json: string): any {
    if (!json || !json.startsWith('{"')) {
        return json
    }
    return transformDateValues(JSON.parse(json))
}

export function toJson(obj: any): string {
    if (!obj || typeof obj === 'string') {
        return obj
    }
    return JSON.stringify(obj)
}

/**
 * create a deep copy of an array of objects
 * for simple arrays can be used: [...array]
 * @param array
 */
export function deepCopyArray<T> (array: T[]): T[] {
    if (!array) {
        return []
    }
    return JSON.parse(JSON.stringify(array))
}

export function fromStorage(key: string): any {
    const stringValue: string | null = localStorage.getItem(key)
    return fromJson(stringValue || '')
}

export function toStorage(key: StorageKey, value: any): void {
    localStorage.setItem(key, toJson(value))
}

export const ISO_8601 = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.\d*)?(?:Z|(\+|-)([\d|:]*))?$/
export const ISO_8601_DATE = /^(\d{4})-(\d{2})-(\d{2})$/

export const isResponseSuccessful = (response: AxiosResponse): boolean => {
    return checkResponseCode(response, 2) && response?.headers?.['content-type'] !== 'text/html'
}

/**
 * Check if the response to the request is a partial success.
 * For that will be checked the status code received as a response.
 */
export const isResponsePartialSuccessful = (response: AxiosResponse): boolean => {
    return checkResponseCode(response, PARTIAL_SUCCESS_RESPONSE_STATUS_CODE) && response?.headers?.['content-type'] !== 'text/html'
}

/**
 * check is response status is in 'startWith' range
 * @param response
 * @param startsWith - ex: 2,4,5
 */
export const checkResponseCode = (response: AxiosResponse, startsWith: number): boolean => {
    return numberStartsWith(response?.status, startsWith)
}

export const numberStartsWith = (input: number | undefined, startsWith: number): boolean => {
    return input ? input.toString().startsWith(startsWith.toString()) : false
}

export const ignoreCaseFilter = (searchStr: string, targetStr = ''): boolean => {
    return targetStr?.toLowerCase().includes(searchStr?.toLowerCase())
}

export const isDev = (): boolean => {
    return process.env?.NODE_ENV === 'development'
}
/**
 * get the index of a value from a enum
 * @param enumType
 * @param enumValue
 */
export const getIndexForEnum = (enumType: any, enumValue: any) => {
    return Object.keys(enumType).indexOf(enumValue)
}

export const generateNumberArray = (length: number, start = 0): number[] => {
    return Array(length)
      .fill(0)
      .map((element, index) => index + start)
}

export const buildEnumOptions = (enumType: any, i18nKeyPrefix: I18nEnumPrefix): OptionItem[] =>
  useMemo(() => {
      const options: OptionItem[] = []
      Object.keys(enumType).forEach(key => {
          // @ts-ignore
          const value = enumType[key]
          options.push({
              key: value,
              value: value,
              label: i18n.t(`${i18nKeyPrefix}.${key}`)
          })
      })
      return options
  }, [])

/**
 * build simple options from a list of strings∂
 * @param options
 */
export const buildSelectOptions = (options: string[]): OptionItem[] => {
    return options.map(value => ({
        key: value,
        value: value,
        label: value
    }))
}
/**
 * extract select options from an object (key = value; value = label)
 * @param values
 */
export const buildSelectOptionsObject = (values: AnyObj): OptionItem[] => {
    return Object.keys(values).map(key => ({
        key: key,
        value: key,
        label: values[key]
    }))
}

export const buildSelectDoctors = (values: DoctorSearchEntry[]): OptionItem[] => {
    const sortedValues = [...values].sort((a, b) => a.name.localeCompare(b.name));

    return sortedValues.map(doctor => ({
        key: doctor.id,
        value: toJson(doctor),
        label: doctor.name
    }))
}

export const generateRandomComponentKey = (): string => {
    // see first answer: https://stackoverflow.com/questions/58285941/how-to-replace-math-random-with-crypto-getrandomvalues-and-keep-same-result
    return Math.floor((crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295) * 100000).toString()
}

export const isObject = (val: any): boolean => {
    return !!val && typeof val === 'object' && val.constructor === Object;
}

export const stringDuplicateFilter = (id: string, index: number, src: string[]) => (src.indexOf(id) === index)
export const removeDuplicates = (src: string[]): string[] => Array.from(new Set(src))

export const compareObjectsByField = <T>(object1: T, object2: T, field: keyof T) => {
    if (!object1 && !object2) {
        return true
    }
    return object1?.[field] !== object2?.[field]
}
/**
 * function used to convert decimal numbers :onBlur
 * @param value
 */
export const convertFloatNumber = (value: any): number | undefined => {
    return isNumber(value) ? Number(value) : toNumber(value)
}
/**
 * convert number to locale, only if the number has no thousands point
 * @param value
 * @param locale
 */
export const toLocaleStringCustom = (value: number | undefined, locale: string): string | undefined => {
    if (!value) {
        return undefined
    }
    return value < 1000 ? value.toLocaleString(locale) : value.toString()
}

/**
 * remove null / undefined / empty string fields from an object
 * also removes non primitive attributes (excluding the Date object)
 * stringify all object's values (problems with boolean and number)
 * sort keys
 * @param obj
 */
export const removeKeyWithEmptyValue = <T>(obj: T): T => {
    if (!isObject(obj)) {
        return obj
    }
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([, value]) => {
                if (value) {
                    return !((isObject(value) && !isValidDate(value)) || Array.isArray(value))
                }
                return false
            })
            .map(([key, value]) => [key, value.toString()])
            .sort(([k1], [k2]) => k1.localeCompare(k2))
    ) as T
}

/**
 * check if two objects are equals, removing all null / undefined / empty string fields
 * @param obj1
 * @param obj2
 */
export const areObjectsEqualsWithExclude = <T>(obj1: T, obj2: T) => {
    if (obj1 && obj2) {
        const obj1Converted = removeKeyWithEmptyValue(obj1)
        const obj2Converted = removeKeyWithEmptyValue(obj2)
        return JSON.stringify(obj1Converted) === JSON.stringify(obj2Converted)
    }

    return !obj1 && !obj2
}

/**
 * check if two lists are equals, removing all null / undefined / empty string fields from each object
 * @param list1
 * @param list2
 */
export const areArraysEqualsWithExclude = <T>(list1: T[], list2: T[]) => {
    if (list1 && list2) {
        const list1Converted = list1.map(it => removeKeyWithEmptyValue(it)).sort((a: any, b: any) => a.toString().localeCompare(b.toString()))
        const list2Converted = list2.map(it => removeKeyWithEmptyValue(it)).sort((a: any, b: any) => a.toString().localeCompare(b.toString()))
        return JSON.stringify(list1Converted) === JSON.stringify(list2Converted)
    }

    return !list1 && !list2
}

/**
 * Function used for combining 2 JSONs. Will get all the JSONs keys and will iterate over them recursively(needed for nested JSONs) until type of the key value is not object.
 */
export const combineJsons = (json1: any, json2: any) => {
    const mergedResult : {[index: string]:any} = {}

    const jsonsKeys = [...Object.keys(json1), ...Object.keys(json2)]

    for (const key of jsonsKeys) {
        if (json1[key] && json2[key] && typeof json1[key] == 'object' && typeof json2[key] == 'object') {
            mergedResult[key] = combineJsons(json1[key], json2[key])
        } else if (json2[key]) {
            mergedResult[key] = json2[key]
        } else {
            mergedResult[key] = json1[key]
        }
    }

    return mergedResult
}
