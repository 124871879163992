import React from 'react'
import {Checkbox, Input as AntInput, InputProps as AntInputProps} from 'antd'
import {ErrorField, InputLabel} from './index'
import {InputLabelProps} from './InputLabel'
import {sharedStyles} from '../../style/shared_styles'
import {ErrorFieldProps} from './ErrorField'
import {inputErrorMessage} from './utils/inputHelper'
import {useDefaultValueSet} from './utils/useDefaultValueSet'

/**
 * The custom component used for rendering an input or a checkbox.
 * Will be used in all components for rendering all the inputs in all the forms.
 * The entered input value will be validated and a message error will be attached to it, if exist.
 */
const Input: React.FC<InputProps> = (props: InputProps) => {
    // need to do this because cannot pass custom attributes to html <input/> tag
    const {
        onValueChanged,
        label,
        mandatory,
        extraInfo,
        pixelAlignTop,
        pixelAlignBottom,
        hideErrorField,
        regex,
        customRegexErrorMessage,
        skipInitialize,
        onBlurValueProcess,
        isInteger,
        ...inputProps
    } = props
    useDefaultValueSet(props.value, onValueChanged, props.defaultValue, skipInitialize)

    const inputLabelProps: InputLabelProps = {label, mandatory, extraInfo}

    const isCheckbox = props.type === 'checkbox'

    const isTypeNumber = props.type === 'number'

    const inputStyle = props.error ? sharedStyles.border : isCheckbox ? {width: '8%'} : {}
    let divStyle: React.CSSProperties = {
        ...sharedStyles.column,
        marginTop: pixelAlignTop ? 2 : 0,
        marginBottom: pixelAlignBottom ? 2 : 0,
        // display: isCheckbox ? 'flex' : 'block',    // Flexbox applied when it's a checkbox
        // alignItems: isCheckbox ? 'center' : 'initial', // Align items center for checkbox
    }

    divStyle = props.wrapperStyle ? {...divStyle, ...props.wrapperStyle} : divStyle

    // delete error when input value is changed
    const onChange = (value: any) => {
        onValueChanged(value)
    }

    const onChangeNumber = (value: any) => {
        const reg = /^-?\d*([,.]\d*)?$/
        if ((!isNaN(value.replace(',', '.')) && reg.test(value)) || value === '' || value === '-') {
            if (isInteger) {
                value = value.replace('.', '').replace(',', '')
            }

            onValueChanged(value)
        }
    }

    // validate input validity on blur event
    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const {value, error} = inputErrorMessage(
            props.value,
            props.mandatory,
            isCheckbox,
            props.min,
            props.max,
            regex,
            customRegexErrorMessage,
            onBlurValueProcess
        )

        if (error || onBlurValueProcess) {
            onValueChanged(value, error)
        }

        props.onBlur?.(event)
    }

    const onBlurNumber = (event: React.FocusEvent<HTMLInputElement>) => {
        let valueTemp = props.value != null ? props.value.toString().replace(',', '.') : ''
        
         // handle '.' or ',' at the end or only '-' in the input box.
        if (valueTemp.charAt(valueTemp.length - 1) === '.' || valueTemp.charAt(valueTemp.length - 1) === ',' || valueTemp === '-') {
            valueTemp = valueTemp.slice(0, -1)
        }

        const result = valueTemp != '' ? +valueTemp.replace(/0*(\d+)/, '$1') : null

        const { value, error } = inputErrorMessage(
            result,
            props.mandatory,
            isCheckbox,
            props.min,
            props.max,
            regex,
            customRegexErrorMessage,
            onBlurValueProcess
        )

        if (error || onBlurValueProcess) {
            onValueChanged(value, error)
        } else {
            onValueChanged(value)
        }

        props.onBlur?.(event)
    }

    const renderInput = () => {
        if (isCheckbox) {
            // @ts-ignore
            return <Checkbox {...inputProps} style={inputStyle} onChange={e => onChange(e.target.checked)} checked={props.value === true} />
        }

        if (isTypeNumber) {
            return <AntInput {...inputProps} value={props.value || ''} type='text' style={inputStyle} onChange={e => onChangeNumber(e.target.value)} onBlur={onBlurNumber} autoComplete={'off'}/>
        }

        return <AntInput {...inputProps} style={inputStyle} onChange={e => onChange(e.target.value)} onBlur={onBlur} autoComplete={'nope'} />
    }

    return (
        <div style={divStyle}>
            {isCheckbox ? (
                <div style={{ display: 'flex'}}>
                    {renderInput()}
                    <div style={{ marginLeft: '8px' }}>
                        <InputLabel {...inputLabelProps} />
                    </div>
                </div>
            ) : (
                <>
                    <InputLabel {...inputLabelProps} />
                    {renderInput()}
                </>
            )}
            <ErrorField error={props.error} hideErrorField={hideErrorField} />
        </div>
    )
}

interface InputProps extends AntInputProps, InputLabelProps, ErrorFieldProps {
    onValueChanged: (val: any, error?: string) => void
    regex?: RegExp
    customRegexErrorMessage?: string
    // inputs and selects seem to have a height difference of 2 pixels, visible when put side by side
    pixelAlignTop?: boolean
    pixelAlignBottom?: boolean
    wrapperStyle?: React.CSSProperties
    onBlurValueProcess?: (val: any) => any | undefined
    isInteger?: boolean
}

export default Input
