import React, {useEffect} from 'react'
import {MedicationSearchEntry, OptionItem, OtherPrescriptionDto} from '../../../utils/types'
import {useForm, useLoading} from '../../../utils/hooks'
import {ActionButtons, CustomSelect, Input, LiveSearch, TextAreaInput, TitleText} from '../../common'
import {i18n} from '../../../i18n'
import {sharedStyles} from '../../../style/shared_styles'
import {buildEnumOptions} from '../../../utils/helper'
import {OtherMedGroup, SearchTypes} from '../../../utils/enums'
import {EditMedKeys, otherMedMandatoryFields} from './utils/keys'
import {observer} from 'mobx-react'
import {useStores} from '../../../store'
import {MedicationUrls} from '../../../store/utils/types'

/**
 * Component for editing other medication in a modal.
 */
const EditOtherMed: React.FC<EditOtherMedProps> = observer((props: EditOtherMedProps) => {
    const [formState, extractProps, onValidateInputs, inputChangeHandler] = useForm(props.otherMed, otherMedMandatoryFields)
    const [editLoading, setEditIsLoading] = useLoading(false)
    const [deleteLoading, setDeleteLoading] = useLoading(false)

    const {therapyStore, userStore} = useStores()

    const therapyTypeOptions: OptionItem[] = buildEnumOptions(OtherMedGroup, 'therapyTab.otherMedication.group')

    const onSubmit = async () => {
        const isValid = onValidateInputs()
        const data: OtherPrescriptionDto = {
            ...(formState.values as OtherPrescriptionDto),
            createdBy: userStore.getUserLoggedName || '',
            name: formState.values.name || '',
            pzn: formState.values.pzn || ''
        }
        if (!isValid || !data) {
            return
        }

        setEditIsLoading(true)
        const isSuccess = await therapyStore.createUpdateMedication(data, MedicationUrls.OTHER)
        setEditIsLoading(false)

        if (isSuccess) {
            props.onCloseModal?.()
        }
    }

    const onMedicationChange = (val: MedicationSearchEntry | string, error?: string) => {
        if (val) {
            if (typeof val === 'string') {
                inputChangeHandler(val, EditMedKeys.name, error)
            } else {
                inputChangeHandler(val.name, EditMedKeys.name, error)
                inputChangeHandler(val.pzn, EditMedKeys.pzn, error)
            }
        } else {
            inputChangeHandler('', EditMedKeys.name, error)
            inputChangeHandler('', EditMedKeys.pzn, error)
        }
    }

    const onDelete = async () => {
        setDeleteLoading(true)
        await therapyStore.deleteMedication(props.otherMed!.id, MedicationUrls.OTHER)
        props.onCloseModal?.()
        setDeleteLoading(false)
    }

    const medication: MedicationSearchEntry | undefined =
        formState.values.pzn || formState.values.name
            ? {
                  id: formState.values.pzn || '',
                  name: formState.values.name || '',
                  dosage: null,
                  pzn: formState.values.pzn || '',
                  unit: '',
                  ingredients: [],
                  companyName: ''
              }
            : undefined

    useEffect(() => {
        if (!props.otherMed) {
            inputChangeHandler(therapyTypeOptions[0].value, EditMedKeys.group)
        }
    }, [])

    return (
        <div>
            <TitleText text={i18n.t('editMed.medicationTitle')} />
            <p>{i18n.t('editMed.otherMedSubtitle')}</p>

            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <div style={sharedStyles.leftColumn}>
                    <CustomSelect label={i18n.t('editMed.group')} items={therapyTypeOptions} {...extractProps(EditMedKeys.group)} />
                </div>
                <div style={sharedStyles.rightColumn}>
                    <Input label={i18n.t('editMed.prescribedBy')} {...extractProps(EditMedKeys.prescribedBy)} />
                </div>
            </div>
            <LiveSearch
                searchType={SearchTypes.product}
                label={i18n.t('editMed.medName')}
                defaultValue={medication}
                mandatory={otherMedMandatoryFields.includes(EditMedKeys.name)}
                error={formState.errors[EditMedKeys.name]}
                onValueChanged={onMedicationChange}
                onSearchValueChanged={val => {
                    inputChangeHandler(val, EditMedKeys.name)
                }}
                searchThreshold={4}
            />

            <div>
                <TitleText text={i18n.t('editMed.additionalTitle')} />
                <TextAreaInput label={i18n.t('editMed.note')} {...extractProps(EditMedKeys.note)} />
            </div>

            <ActionButtons
                onCancel={props.onCloseModal}
                renderDelete={props.otherMed && therapyStore.canDeleteMedication}
                onDelete={onDelete}
                onSave={onSubmit}
                loadingSave={editLoading}
                loadingDelete={deleteLoading}
            />
        </div>
    )
})

type EditOtherMedProps = {
    otherMed?: OtherPrescriptionDto
    onCloseModal?: () => void
}

export default EditOtherMed
