import BaseService from './base_service'
import {IObservationService} from './utils/types'
import {AxiosRequestHeaders, AxiosResponse} from 'axios'
import {SideEffectType, VitalDataType} from '../utils/types'
import {useAuthHeader} from '../utils/hooks/useAuthHeader'

class ObservationService extends BaseService implements IObservationService {

    async getTherapySideEffects(therapyId: string): Promise<AxiosResponse<SideEffectType[]>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.get(`/api/private/side-effect/${therapyId}`, {
            headers: {
                ...authHeader
            }
        })
    }

    async getTherapyVitalData(therapyId: string): Promise<AxiosResponse<VitalDataType[]>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.get(`/api/private/vital-data/${therapyId}`, {
            headers: {
                ...authHeader
            }
        })
    }

    async getInitialSideEffects(therapyId: string): Promise<AxiosResponse<SideEffectType[]>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.get(`/api/private/side-effect/init/${therapyId}`, {
            headers: {
                ...authHeader
            }
        })
    }

    async getInitialVitalData(therapyId: string): Promise<AxiosResponse<VitalDataType[]>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.get(`/api/private/vital-data/init/${therapyId}`, {
            headers: {
                ...authHeader
            }
        })
    }
}

export default ObservationService
