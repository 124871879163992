import React from 'react'
import {DiagnosisType} from '../../../../utils/types'
import {EditFilled} from '@ant-design/icons'
import {styles} from '../../styles/TherapySection.style'
import {Color} from '../../../../style/custom/colors'
import {Tooltip} from 'antd'
import {i18n} from '../../../../i18n'

/**
 * Component representing a patient diagnosis entry.
 */
const DiagnosisEntry: React.FC<DiagnosisEntryProps> = props => {
    const diagnosis = props.diagnosis
    return (
        <div style={styles.wrapper}>
            <div style={styles.codeContainer}>
                <span style={{color: Color.white}}>{diagnosis.icdCode}</span>
            </div>
            <div style={styles.name}>
                <span>{diagnosis.title}</span>
            </div>
            <Tooltip title={i18n.t('button.edit')}>
                {diagnosis.id && !props.hasOperationsErrors && <EditFilled style={styles.icon} onClick={() => props.onEdit(diagnosis)} />}
            </Tooltip>
        </div>
    )
}

type DiagnosisEntryProps = {
    diagnosis: DiagnosisType
    onEdit: (diagnosis: DiagnosisType) => void
    hasOperationsErrors?: boolean | undefined | null
}

export default DiagnosisEntry
