import React from 'react'
import EditDetailsEntry from '../subcomponents/EditDetailsEntry'
import {PatientGeneralInfo} from '../../../../utils/types'
import {i18n} from '../../../../i18n'
import {ClientKeys, EditAddressKey, EditClientKey} from '../../actions/utils/keys'
import {formatAddress} from '../../../../utils/uiHelper'

/**
 * Component displaying patient contact data.
 */
const ContactPanel: React.FC<ContactPanelProps> = (props: ContactPanelProps) => {
    const onEditDetails = (input: ClientKeys) => props.onEdit(input)

    return (
        <div style={{width: '50%'}}>
            <EditDetailsEntry
                title={i18n.t('userDataTab.contact.address')}
                value={formatAddress(props.patient.address)}
                onEdit={() => onEditDetails(EditAddressKey.street)}
                hasOperationsErrors={props.hasOperationsErrors}
            />
            <EditDetailsEntry
                title={i18n.t('userDataTab.contact.phone')}
                value={props.patient.phone}
                onEdit={() => onEditDetails(EditClientKey.phone)}
                hasOperationsErrors={props.hasOperationsErrors}
            />
            <EditDetailsEntry
                title={i18n.t('userDataTab.contact.cellphone')}
                value={props.patient.cellphone}
                onEdit={() => onEditDetails(EditClientKey.cellphone)}
                hasOperationsErrors={props.hasOperationsErrors}
            />
            <EditDetailsEntry
                title={i18n.t('userDataTab.contact.email.title')}
                value={props.patient.email}
                onEdit={() => onEditDetails(EditClientKey.email)}
                extraInfo={i18n.t('userDataTab.contact.email.extra')}
                hasOperationsErrors={props.hasOperationsErrors}
            />
        </div>
    )
}

type ContactPanelProps = {
    patient: PatientGeneralInfo
    onEdit: (input: ClientKeys) => void
    hasOperationsErrors?: boolean | undefined | null
}

export default ContactPanel
