import {IPatientTherapyService} from './utils/types'
import {AxiosRequestHeaders, AxiosResponse} from 'axios'
import {PatientTherapyRowDto} from '../utils/types'
import {PatientTherapyFilter} from '../utils/enums'
import BaseService from './base_service'
import {useAuthHeader} from '../utils/hooks/useAuthHeader'

class PatientTherapyService extends BaseService implements IPatientTherapyService {
    async getPatientTherapies(filter: PatientTherapyFilter): Promise<AxiosResponse<PatientTherapyRowDto[]>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.get('/api/private/therapy/patient-therapies?filter=' + filter, {
            headers: {
                ...authHeader
            }
        })
    }
}

export default PatientTherapyService
