const Color = {
    base1: '#003A6C',
    base2: '#00599A',
    base3: '#00599A',
    base4: '#F29663',
    base5: '#FECC6B',
    base6: '#D5BEDF',

    secondary1: '#397CBF',
    secondary2: '#72B2EA',
    secondary3: '#D0E8F4',

    active: '#00599A',
    activeDark: '#003A6C',
    neutral1: '#36312B',
    neutral2: '#6A655F',
    neutral3: '#B5AFA5',
    neutral4: '#DDD8D0',
    neutral5: '#F0EDE7',

    white: '#ffffff',
    black: '#000000',
    green: '#42B57D',
    yellow: '#fce900',
    transparent: 'transparent',
    warning: '#F76A6A',
    warningStrong: '#FA3E3E',
    warningYellow: '#EED202',

    lightPurple: '#F5EEF8'
}

module.exports = {
    Color
}
