import React from 'react'
import EditDetailsEntry from '../subcomponents/EditDetailsEntry'
import {PatientGeneralInfo} from '../../../../utils/types'
import {format} from 'date-fns'
import {Format} from '../../../../utils/constants'
import {i18n} from '../../../../i18n'
import {ClientKeys, EditClientKey} from '../../actions/utils/keys'

/**
 * Component displaying patient general data.
 */
const GeneralInfoPanel: React.FC<GeneralInfoPanelProps> = (props: GeneralInfoPanelProps) => {
    const onEditDetails = (input: ClientKeys) => props.onEdit(input)

    const buildFullName = () => {
        const firstLastName = props.patient.firstName + ' ' + props.patient.lastName
        if (!props.patient.formOfAddress) {
            return firstLastName
        }
        return props.patient.formOfAddress + ' ' + firstLastName
    }

    return (
        <div style={{width: '50%'}}>
            <div style={{flex: 1}}>
                <EditDetailsEntry
                    title={i18n.t('userDataTab.general.title')}
                    value={props.patient.academicTitle}
                    onEdit={() => onEditDetails(EditClientKey.academicTitle)}
                    hasOperationsErrors={props.hasOperationsErrors}
                />
                <EditDetailsEntry
                    title={i18n.t('userDataTab.general.name')}
                    value={buildFullName()}
                    onEdit={() => onEditDetails(EditClientKey.firstName)}
                    hasOperationsErrors={props.hasOperationsErrors}
                />
                <EditDetailsEntry
                    title={i18n.t('userDataTab.general.birthday')}
                    value={props.patient.birthday ? format(props.patient.birthday, Format.DateFormat) : undefined}
                    onEdit={() => onEditDetails(EditClientKey.birthday)}
                    hasOperationsErrors={props.hasOperationsErrors}
                />
                <EditDetailsEntry
                    title={i18n.t('userDataTab.general.gender')}
                    value={props.patient.gender ? props.patient.gender : undefined}
                    onEdit={() => onEditDetails(EditClientKey.gender)}
                    hasOperationsErrors={props.hasOperationsErrors}
                />
            </div>
        </div>
    )
}

type GeneralInfoPanelProps = {
    patient: PatientGeneralInfo
    onEdit: (input: ClientKeys) => void
    hasOperationsErrors?: boolean | undefined | null
}

export default GeneralInfoPanel
