import React, {CSSProperties} from 'react'
import {Color} from '../../style/custom/colors'


/**
 * Die Konvertierung von "ß" nach "SS" muss vermieden werden, weil sich sonst
 * u.U. die Wortbedeutung ändert.
 * Beispiel: Körpermaße wird zu KÖRPERMASSE. 😬
 */
const customToUpperCase = (text: string): string => {
    return text
        .split('')
        .map(char => (char === 'ß' ? char : char.toUpperCase()))
        .join('');
};


/**
 * The component used for rendering the title of the modals or of the major page sections.
 * The displayed title value will have a custom style.
 */
const TitleText: React.FC<TitleTextProps> = (props: TitleTextProps) => {
    return (
        <span
            style={{
                color: props.color || Color.active,
                fontSize: props.size || 18,
                fontWeight: 'bold',
                ...props.style
            }}>
            {customToUpperCase(props.text)}
        </span>
    )
}

type TitleTextProps = {
    text: string
    size?: number
    color?: string
    style?: CSSProperties
}

export default TitleText
