import React from 'react'
import {Locale, useLocale} from '../../i18n'
import {Affix, Button, Layout} from 'antd'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import AboutUs from '../about/AboutUs'
import Home from '../home/Home'
import {Content} from 'antd/es/layout/layout'
import AppHeader from '../header/AppHeader'
import {Routes as CustomRoutes} from '../../utils/routes'
import {styles} from './styles/App.style'
import PatientPage from '../patient/PatientPage'
import ProfilePage from '../profile/ProfilePage'
import FullLoadingOverlay from '../common/FullLoadingOverlay'
import {observer} from 'mobx-react'
import {useStores} from '../../store'
import Impressum from "../impressum/Impressum";

/**
 * The main component. Is the starting point of the application.
 * Is responsible for switching between the application pages.
 */
const App: React.FC = observer(() => {
    const [locale, setLocale] = useLocale()

    const {uiStore} = useStores()

    return (
        <div key={locale.toString()}>
            <BrowserRouter>
                <Layout style={{minHeight: '100vh'}}>
                    <AppHeader />
                    <Content style={styles.content}>
                        <Routes>
                            <Route path='/' element={<Home />} />

                            <Route path={CustomRoutes.ABOUT} element={<AboutUs />} />
                            <Route path={CustomRoutes.IMPRESSUM} element={<Impressum />} />
                            <Route path={CustomRoutes.PATIENT + '/:patientId'}>
                                <Route path=':therapyId' element={<PatientPage />} />
                                <Route path='' element={<PatientPage />} />
                            </Route>

                            <Route path={CustomRoutes.NEW_PATIENT} element={<Home newPatient={true} />} />

                            <Route path={CustomRoutes.MY_PROFILE} element={<ProfilePage />} />
                        </Routes>
                        <FullLoadingOverlay open={uiStore.showLoading} key={uiStore.showLoading ? '1' : '0'} />
                    </Content>
                </Layout>

                {!process.env.REACT_APP_PROD && (
                    <Affix style={styles.devWrap}>
                        <div style={styles.devLang}>
                            <Button
                                type='primary'
                                onClick={() => {
                                    setLocale(Locale.EN)
                                }}>
                                EN
                            </Button>
                            <Button
                                type='primary'
                                danger={true}
                                onClick={() => {
                                    setLocale(Locale.DE)
                                }}>
                                DE
                            </Button>
                        </div>
                    </Affix>
                )}
            </BrowserRouter>
        </div>
    )
})

export default App
