import React from 'react'

/**
 * The component used for rendering an empty div with a custom margin top.
 */
const VSpace: React.FC<VSpaceProps> = props => {
    return <div style={{marginTop: props.size}} />
}

type VSpaceProps = {
    size: number | string
}

export default VSpace
