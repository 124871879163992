import {IDiagnoseService} from './utils/types'
import {AxiosRequestHeaders, AxiosResponse} from 'axios'
import {DiagnosisType} from '../utils/types'
import BaseService from './base_service'
import {useAuthHeader} from '../utils/hooks/useAuthHeader'

class DiagnoseService extends BaseService implements IDiagnoseService {

    async createUpdateDiagnose(diagnose: DiagnosisType): Promise<AxiosResponse<void>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        if (diagnose.id) {
            return this.client.put(`/api/private/diagnose/${diagnose.id}`, diagnose, {
                headers: {
                    ...authHeader
                }
            })
        }
        return this.client.post('/api/private/diagnose/', diagnose, {
            headers: {
                ...authHeader
            }
        })
    }
}

export default DiagnoseService
