import {IObservationStore} from './utils/types'
import {SideEffectType, VitalDataType} from '../utils/types'
import {makeAutoObservable} from 'mobx'
import {IObservationService} from '../service/utils/types'
import {isResponseSuccessful} from '../utils/helper'

class ObservationStore implements IObservationStore {
    sideEffects: SideEffectType[] | null = null
    vitalData: VitalDataType[] | null = null
    observationService: IObservationService
    getCurrentTherapyId: () => string | undefined

    constructor(sideEffectService: IObservationService, getCurrentTherapyId: () => string | undefined) {
        makeAutoObservable(this)
        this.observationService = sideEffectService
        this.getCurrentTherapyId = getCurrentTherapyId
    }

    async getSideEffectsForTherapy(): Promise<void> {
        const therapyId = this.getCurrentTherapyId()
        if (!therapyId) {
            console.error(`Therapy id is null`)
            return Promise.resolve()
        }
        const response = await this.observationService.getTherapySideEffects(therapyId)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to get side effects for therapy ${therapyId}, ${response}`)
            return Promise.resolve()
        }
        this.setSideEffects(response.data)
        return Promise.resolve()
    }

    async getVitalDataForTherapy(): Promise<void> {
        const therapyId = this.getCurrentTherapyId()
        if (!therapyId) {
            console.error(`Therapy id is null`)
            return Promise.resolve()
        }
        const response = await this.observationService.getTherapyVitalData(therapyId)

        if (!isResponseSuccessful(response)) {
            console.log(`Failed to get vital data for therapy ${therapyId}, ${response}`)
            return Promise.resolve()
        }
        this.vitalData = response.data
        return Promise.resolve()
    }

    async refreshObservations() {
        const therapyId = this.getCurrentTherapyId()
        if (!therapyId) {
            return
        }
        await Promise.all([this.getSideEffectsForTherapy(), this.getVitalDataForTherapy()])
    }

    resetObservations(): void {
        this.sideEffects = null
        this.vitalData = null
    }

    setSideEffects (sideEffects: SideEffectType[] | null) {
        this.sideEffects = sideEffects;
    }

    async cleanup() {
        this.sideEffects = null
        return Promise.resolve(undefined)
    }
}

export default ObservationStore
