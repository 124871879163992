import {LocalStyleSheet} from "../../../utils/types";
import {Color} from "../../../style/custom/colors";

export const styles: LocalStyleSheet = {
    wrapper: {
        display: 'flex',
        width: '100%',
        padding: 5,
    },
    codeContainer: {
        display: 'flex',
        width: '15%',
        backgroundColor: Color.neutral2,
        alignSelf: 'center',
        justifyContent: 'center'
    },
    name: {
        flex: 1,
        alignSelf: 'center',
        paddingLeft: '3%'
    },
    icon: {
        color: Color.neutral2,
        fontSize: '110%',
        alignSelf: 'center',
        cursor: 'pointer'
    }
}
