import {
    IDoctorStore,
    IObservationStore,
    IPatientStore,
    IPatientTherapyStore,
    ITherapyStore,
    IUIStore,
    IUserStore,
    Store
} from './utils/types'
import RootService, {IRootService} from '../service/root_service'
import PatientTherapyStore from './patient_therapy_store'
import {createContext, useContext} from 'react'
import UserStore from './user_store'
import {useLocalObservable} from 'mobx-react'
import PatientStore from './patient_store'
import TherapyStore from './therapy_store'
import Keycloak from 'keycloak-js'
import ObservationStore from './observation_store'
import UIStore from './ui_store'
import DoctorStore from './doctor_store'

class StateManager {
    stores: IAppStores
    services: IRootService

    constructor() {
        this.services = new RootService()
        this.stores = this.createNewStores()

        this.initStore()
    }

    private createNewStores = (): IAppStores => {
        const ser = this.services
        const uiStore = new UIStore()
        const patientStore = new PatientStore(ser.patientService, ser.searchService, ser.diagnoseService, uiStore)
        const getCurrentTherapyId = () => this.stores.therapyStore.therapyComplete?.generalInfo.id
        const observationStore = new ObservationStore(ser.observationService, getCurrentTherapyId)

        return {
            patientStore,
            patientTherapyStore: new PatientTherapyStore(ser.patientTherapyService),
            userStore: new UserStore(ser.patientService),
            therapyStore: new TherapyStore(ser.therapyService, patientStore, observationStore, uiStore),
            observationStore: observationStore,
            doctorStore: new DoctorStore(ser.doctorService),
            uiStore
        }
    }

    private initStore = () => {
        Object.values(this.stores).forEach((store: Store) => {
            if (typeof store.initialize === 'function') store.initialize()
        })
    }

    // EDIT: don't need to call explicitly because after logout the entire JS will reload and the stores will be recreated
    private async reset() {
        const cleanupPromises: Promise<any>[] = []
        // Call the cleanup function for each store if present
        Object.values(this.stores).forEach((store: Store) => {
            if (typeof store.cleanup === 'function') cleanupPromises.push(store.cleanup())
        })

        // make sure cleanups are done before we re-init everything
        await Promise.all(cleanupPromises)
    }
}

export type IAppStores = {
    patientStore: IPatientStore
    patientTherapyStore: IPatientTherapyStore
    userStore: IUserStore
    therapyStore: ITherapyStore
    observationStore: IObservationStore
    uiStore: IUIStore
    doctorStore: IDoctorStore
}

export const rootStore = new StateManager()

const createStateManager = () => rootStore

// @ts-ignore
export const storeContext = createContext<StateManager>(null)

export const useStores = (): IAppStores => {
    const state: StateManager = useContext<StateManager>(storeContext)
    return state?.stores
}
export const useServices = (): IRootService => {
    const state: StateManager = useContext<StateManager>(storeContext)
    return state?.services
}

export const StoreContextProvider = (props: any) => {
    const stateManager = useLocalObservable(createStateManager)
    return <storeContext.Provider value={stateManager}>{props.children}</storeContext.Provider>
}

export const useAuthToken = async (): Promise<string> => {
    await rootStore.stores.userStore.refreshAccessToken()
    return rootStore.stores.userStore.keycloak?.token || ''
}

export const useLogout = (): Keycloak.KeycloakPromise<void, void> | undefined => {
    return rootStore.stores.userStore.keycloak?.logout() || undefined
}

export type RootStoreManager = StateManager
