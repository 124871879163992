import {IReactionDisposer} from 'mobx'
import Keycloak from 'keycloak-js'
import {
    AnyObj,
    ChangePassDto,
    CompleteTherapyDto,
    DiagnosisType,
    DoctorSearchEntry,
    OtherPrescriptionDto,
    PartialSuccessResponse,
    PatientGeneralInfo,
    PatientOverviewType,
    PatientTherapyRowDto,
    ProfileDto,
    RequestedPrescriptionDto,
    SideEffectType,
    TherapyGeneralInfo,
    TherapyPrescriptionDto,
    UpdateObservationType,
    VitalDataType
} from '../../utils/types'
import {ConfigTypes, PatientTherapyFilter} from '../../utils/enums'

export interface IPatientStore extends Store {
    patientOverview: PatientOverviewType | null | undefined
    loadPatient: (id: string, deleteCurrent?: boolean) => void
    refreshPatient: () => Promise<void>
    updatePatient: (patient: PatientGeneralInfo) => Promise<boolean | PartialSuccessResponse>
    createPatient: (patient: PatientGeneralInfo) => Promise<string | PartialSuccessResponse>
    createUpdateDiagnoses: (diagnoses: DiagnosisType) => Promise<boolean>
    setPatientOverview: (patient: PatientOverviewType | null) => void
}

export interface IDoctorStore extends Store {
    getTherapyDoctorList: () => Promise<void>
    readonly getDoctors: DoctorSearchEntry[]
}

export interface IPatientTherapyStore extends Store {
    patientTherapies: PatientTherapyRowDto[]
    initPatientTherapies: (filter: PatientTherapyFilter) => Promise<boolean>
}

export interface IObservationStore extends Store {
    sideEffects: SideEffectType[] | null
    vitalData: VitalDataType[] | null
    getSideEffectsForTherapy: () => Promise<void>
    getVitalDataForTherapy: () => Promise<void>
    refreshObservations: () => Promise<void>
    resetObservations: () => void
}

export interface ITherapyStore extends Store {
    therapyComplete: CompleteTherapyDto | null
    selectedTherapyId: string | null
    getTherapyById: (id: string, showLoading?: boolean) => Promise<void>
    createUpdateTherapy: (therapy: TherapyGeneralInfo, therapyCloneId?: string) => Promise<boolean>
    refreshTherapy: () => Promise<void>
    createUpdateMedication: (med: TherapyPrescriptionDto | RequestedPrescriptionDto | OtherPrescriptionDto, type: MedicationUrls) => Promise<boolean>
    deleteMedication: (therapyMedId: string, type: MedicationUrls) => Promise<void>
    setTherapyComplete: (therapy: CompleteTherapyDto | null) => void
    setSelectedTherapyId: (therapyId: string | null) => void
    readonly canDeleteMedication: boolean
    readonly canUpdateMedication: boolean
    readonly showObservations: boolean
    readonly hasTherapyMedication: boolean
    generateReport: (therapyId: string, patientId: string) => Promise<void>
    exportTherapies: (patientId: string) => Promise<boolean>
    requestToCheckTherapy: () => Promise<void>
    closeTherapy: () => Promise<void>
    releaseTherapy: (sideEffects: UpdateObservationType[], vitalData: UpdateObservationType[]) => Promise<void>
}

export interface IUserStore extends Store {
    keycloak: Keycloak.KeycloakInstance | null
    userProfile: ProfileDto | null
    userConfigs: Map<ConfigTypes, any> | null | undefined

    setKeycloak: (instance: Keycloak.KeycloakInstance | null) => void
    getUserConfigsServer: () => Promise<void>
    refreshAccessToken: () => Promise<void>
    logout: () => void
    updateProfile: (profile: ProfileDto) => Promise<boolean>
    changePassword: (changePass: ChangePassDto) => Promise<boolean>
    readonly getUserLoggedName: string
    readonly getTherapyNameList: string[]
    readonly getFederalStates: string[]
    readonly getClientTitlesList: string[]
    readonly getFormOfAddress: string[]
    readonly getGender: AnyObj
    readonly getUnitMeasure: AnyObj
    readonly getI18nText: (configType: ConfigTypes, key: string) => string
    readonly getMaxWeight: number | undefined
    readonly getTinoDocUrl: string | undefined
    readonly isDoctor: boolean
}

export interface IUIStore extends Store {
    showLoading: boolean
    setShowLoading: (showLoading: boolean) => void
}

export interface Store {
    autorunCleaners?: IReactionDisposer[]
    cleanup?: () => Promise<void>
    initialize?: () => void
    registerAutoruns?: () => void
}

export enum MedicationUrls {
    THERAPY = 'therapy-prescription',
    REQUIRED = 'required-medication',
    OTHER = 'other-medication'
}
