/**
 * Helper for Medication
 */
import {
    IngredientSearchEntry,
    MedicationSearchEntry,
    OtherPrescriptionDto,
    RequestedPrescriptionDto,
    TherapyGeneralInfo,
    TherapyMedicationDto,
    TherapyPrescriptionDto,
    TimeDosage
} from './types'
import React from 'react'
import {i18n} from '../i18n'
import {sharedStyles} from '../style/shared_styles'
import {generateNumberArray, generateRandomComponentKey, removeDuplicates} from './helper'
import {Color} from '../style/custom/colors'
import {styles} from '../components/patient/styles/TherapyMedicationTable.style'
import {TherapyStatus} from './enums'
import {MAX_CYCLE_DURATION} from '../components/common/hooks/useTherapyDetails'
import {EditFilled} from '@ant-design/icons'
import {toNumber} from './observation_helper'
import {Tooltip} from 'antd'
import { addDays } from 'date-fns';
import moment from 'moment'

/**
 * Processes the timeDose entries for display
 * @param therapyMedications
 * @param unit - the unit set on the entire prescription
 * @return an object with a dynamic number of properties of type
 * {
 *      [time: string]: {medication: Medication, dosage: number}
 *      ...
 * }
 */
function processTimeDose(therapyMedications: TherapyMedicationDto[], unit: string): any {
    return therapyMedications.reduce((r: Record<string, {medication: MedicationSearchEntry; multiplier: number; unit: string}[]>, therapyMed) => {
        const {timeDoses, ...medication} = therapyMed
        timeDoses.forEach(td => {
            const id = td.time
            if (r[id] || (r[id] = [])) {
                r[id].push({medication: medication, multiplier: td.multiplier, unit: unit})
            }
        })
        return r
    }, {})
}

/**
 * Gets the columns used in therapy medication table
 */
export const getTherapyMedicationColumns = (onEdit?: ((med: TherapyPrescriptionDto) => void) | false) => {
    const columns = [
        {
            title: i18n.t('therapyTab.therapyMedication.column.ingredient'),
            dataIndex: 'ingredientName',
            key: 'ingredientName',
            ellipsis: {
                showTitle: false
            },
            render: (val: string) => (
                <Tooltip placement='topLeft' title={val}>
                    {val}
                </Tooltip>
            ),
            width: '20%'
        },
        {
            title: i18n.t('therapyTab.therapyMedication.column.timeDose'),
            dataIndex: 'timeDosesWithUnit',
            key: 'timeDosesWithUnit',
            width: '45%',
            render: (therapyMedications: TherapyMedicationDto[], record: TherapyPrescriptionDto) => {
                const processedTimeDoses: any = processTimeDose(therapyMedications, record.unit)
                const totalEntries = Object.keys(processedTimeDoses).length
                return (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        {Object.keys(processedTimeDoses).map((time, idx) => {
                            // @ts-ignore
                            const processedEntries: {medication: Medication; multiplier: number; unit?: string}[] = processedTimeDoses[time]
                            // don't add divider to last element
                            const dividerStyle = totalEntries === idx + 1 ? {} : sharedStyles.divider
                            return (
                                <div key={generateRandomComponentKey()} style={{...styles.timeDoseWrapper, ...dividerStyle}}>
                                    <span>{i18n.t('common.time', {time})}</span>
                                    <div style={{width: '82%'}}>
                                        <div style={styles.processedWrapper}>
                                            {processedEntries.map((el: {medication: MedicationSearchEntry; multiplier: number; unit?: string}) => (
                                                <div key={generateRandomComponentKey()} style={styles.processedEntry}>
                                                    <div style={styles.dosageWrapper}>
                                                        <span style={styles.dosageText}>
                                                            {el.multiplier?.toLocaleString(i18n.locale)}x{el.medication.dosage?.toLocaleString(i18n.locale)}{' '}
                                                            {el.unit}
                                                        </span>
                                                    </div>
                                                    <span style={{flex: 3}}>{el.medication.name}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            }
        },
        {
            title: i18n.t('therapyTab.therapyMedication.column.duration'),
            dataIndex: 'therapy',
            key: 'therapy',
            width: '10%',
            render: (therapy: TherapyGeneralInfo, record: TherapyPrescriptionDto) => {
                // console.log('therapy', therapy);
                // console.log('record', record);

                let daysText = '-';
                if(therapy && record){
                    const therapyDates = calculateTherapyDates(therapy.startDate, record.days, therapy.cycleDuration);
                    if(therapyDates){
                        const startText = moment(therapyDates[0]).format('DD.MM.YYYY');
                        const endText = moment(therapyDates[1]).format('DD.MM.YYYY');
                        daysText = `${startText} - ${endText}`;
                    }
                }

                return (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <span>
                        {daysText}
                        </span>
                    </div>
                );
            }
        },
        {
            title: i18n.t('therapyTab.therapyMedication.column.type'),
            dataIndex: 'type',
            key: 'type',
            width: '10%',
            render: (type: string) => i18n.t(`therapyTab.therapyMedication.type.${type}`)
        },
        {
            title: i18n.t('therapyTab.therapyMedication.column.dailyDose'),
            dataIndex: 'timeDosesWithUnit',
            key: 'dailyDose',
            width: '10%',
            render: (therapyMedications: TherapyMedicationDto[], record: TherapyPrescriptionDto) => {
                let totalDosage = 0
                let unit = ''
                therapyMedications.forEach((med, medIndex) => {
                    const medDosage = med.dosage || 0
                    med.timeDoses.forEach((time, timeIndex) => {
                        const dosage = time.multiplier || 0
                        totalDosage += dosage * medDosage

                        if (med.unit && unit === '') {
                            unit = med.unit;
                        }
                    })
                })
        
                return (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <span>
                            {totalDosage >= 0 ? `${prepareDosageForDisplay(totalDosage)} ${unit}` : '-'}
                        </span>
                    </div>
                );
            }
        }
    ]
    if (onEdit) {
        columns.push({
            title: '',
            key: 'therapyTableEdit',
            width: '5%',
            // @ts-ignore - ts is stupid and expects the inferred the type of the first defined render (of timeDose)
            render: (val: any, record: TherapyPrescriptionDto) => <EditFilled style={{fontSize: 24}} onClick={() => onEdit(record)} />,
            // @ts-ignore
            align: 'right'
        })
    }
    return columns
}

/**
 * Transforms a string of type '1, 3-5, 10' into [1,3,4,5,10]
 * Returns an array of 2 elements: an array of numbers representing days, and a boolean representing the error state
 * If the returned boolean is true, there was an error during transformation
 * @param days a range in string format
 * @param limit - the upper limit of the array, necessary to limit the user in choosing days past therapy end duration
 */
export const getDaysArray = (days: string, limit = MAX_CYCLE_DURATION): [number[], string] => {
    const result: number[] = []
    let error = false
    const subArrays = days.split(',')
    let wrongDaysOrderMessage: string | undefined
    subArrays.forEach(str => {
        if (str.includes('-')) {
            const [startRange, endRange] = str
                .split('-')
                .filter(s => !!s)
                .map(strBit => Number(strBit))
            if (isNaN(startRange) || isNaN(endRange)) {
                error = true
                return
            }
            if (Number(startRange) > Number(endRange)) {
                wrongDaysOrderMessage = i18n.t('editMed.error.wrongRange', {startRange: startRange, endRange: endRange})
            }
            const generatedLength = endRange - startRange + 1
            const numberArr = generateNumberArray(generatedLength < 0 ? 1 : generatedLength, startRange)
            result.push(...numberArr)
            return
        }
        const nbr = Number(str)
        if (nbr && !isNaN(nbr)) {
            result.push(nbr)
        }
    })
    const outOfRange = result.some(nr => nr > limit)
    error = !!days && result.length < 1
    const errorMessage = error
        ? i18n.t('common.errors.invalid')
        : wrongDaysOrderMessage
        ? wrongDaysOrderMessage
        : outOfRange
        ? i18n.t('editMed.error.outOfRange', {cycleDuration: limit})
        : ''
    return [result, errorMessage]
}

/**
 * Transforms an array like [1,3,4,5,10] into a string of type '1, 3-5, 10'
 */
export const getDaysString = (days: number[]): string => {
    if (days.length < 1) {
        return ''
    }
    // counts consecutive numbers
    let counter = 0
    days.sort((a, b) => a - b)
    let res = days[0]?.toString()
    days.forEach((number, idx, numbers) => {
        if (idx === 0) return
        // High difference between consecutive numbers means no range, split by ','
        if (number - numbers[idx - 1] > 1) {
            res += ', ' + number
            counter = 0
            return // do nothing
        }
        // Low difference is directly counted as consecutive
        counter++
        if (counter < 2) {
            // 2 consecutive numbers will still be split by ,
            res += ', ' + number
            return
        }
        if (counter === 2) {
            // 3 consecutive numbers means deleting the middle one and linking by '-'
            res = res.substr(0, res.lastIndexOf(','))
            res += '-' + number
            return
        }
        if (counter > 2) {
            // after the '-' link is there just replace the max value
            res = res.substr(0, res.lastIndexOf('-'))
            res += '-' + number
            return
        }
    })
    return res
}

/**
 * Used to get the box background and text color.
 * The text color is also affected externally by the render condition inside Box component.
 * @param isIntake colors specific to days when medicine should be taken
 * @param isExcluded colors specific to days outside the therapy plan
 * @param isCurrentDay colors specific to marking the current day
 */
export const getBoxColor = (isIntake: boolean, isExcluded: boolean, isCurrentDay: boolean): {squareColor: string; textColor: string} => {
    const colors = (squareColor: string, textColor = Color.transparent) => {
        return {squareColor, textColor}
    }
    if (isIntake) {
        return isCurrentDay ? colors(Color.base5) : colors(Color.active, Color.white)
    }
    if (isExcluded) {
        return colors(Color.white)
    }
    if (isCurrentDay) {
        return colors(Color.base4)
    }
    return colors('lightgray', Color.black)
}

/**
 * Used to transform timeDoses array into the 3 arrays required for display
 */
export const getTimeDoseArrays = (
    therapyMedications: TherapyMedicationDto[]
): {timeArr: string[]; medArr: (MedicationSearchEntry | string)[]; medUnitArr: (MedicationSearchEntry | string)[]; doseArr: number[][]; doseErr: string[][]; unitArr: string[]} => {
    const medArr: (MedicationSearchEntry | string)[] = []
    const medUnitArr: (MedicationSearchEntry | string)[] = []
    const timeArr: string[] = []
    const doseArr: number[][] = []
    const doseErr: string[][] = []
    const unitArr: string[] = []
    //create time array with all entries
    therapyMedications.forEach((td: TherapyMedicationDto) => {
        const {timeDoses} = td
        timeDoses.forEach(timeDose => {
            const existingTimeIndex = timeArr.findIndex(t => t === timeDose.time)
            if (existingTimeIndex === -1) {
                timeArr.push(timeDose.time)
            }
        })
    })
    //sort array by time
    timeArr.sort((a, b) => a.localeCompare(b))

    therapyMedications.forEach((td: TherapyMedicationDto, index) => {
        const {timeDoses, ...medication} = td
        // if the medication does not have name => anonymous medication (only ingredients and dosage selected)
        const medCopy = !medication.name
            ? medication.dosage?.toLocaleString(i18n.locale) || ''
            : {
                  ...medication,
                  uiDosage: medication.dosage?.toLocaleString(i18n.locale)
              } // copy uiDosage
        medArr.push(medCopy)
        // same for unit
        const medUnitCopy = !medication.name
            ? medication.unit || ''
            : {
                  ...medication,
                  unit: medication.unit
              } // copy unit
        medUnitArr.push(medUnitCopy)

        doseArr.push([]) // otherwise last line crashes
        doseErr.push([])
        unitArr.push(td.unit)
        timeDoses.forEach(timeDose => {
            const finalIndex = timeArr.findIndex(value => value === timeDose.time)
            // @ts-ignore
            doseArr[index][finalIndex] = timeDose.multiplier || undefined
            doseErr[index][finalIndex] = ''
        })
    })
    return {timeArr, medArr, medUnitArr, doseArr, doseErr, unitArr}
}

/**
 * Transforms the display arrays into the corresponding timeDose objects
 */
export const getTimeDoseObj = (
    timeArr: string[],
    medArr: (MedicationSearchEntry | string)[],
    doseArr: number[][],
    unitArr: (MedicationSearchEntry | string)[],
    ingredients?: IngredientSearchEntry[]
): TherapyMedicationDto[] => {
    const therapyMedication: TherapyMedicationDto[] = []
    medArr.forEach((medication, medIndex) => {
        const timeDoses: TimeDosage[] = []
        timeArr.forEach((time, timeIndex) => {
            const dosage = doseArr[medIndex]?.[timeIndex]
            if (dosage) {
                timeDoses.push({time, multiplier: dosage})
            }
        })

        const medOrUnit = unitArr[medIndex]
        const unit = typeof medOrUnit === 'string' ? medOrUnit : medOrUnit.unit
        let medicationToAdd: MedicationSearchEntry

        if (typeof medication === 'string') {
            // if not med entry, need to have the ingredients
            if (!ingredients || ingredients?.length === 0) {
                return
            }
            medicationToAdd = {
                id: '',
                name: '',
                unit,
                dosage: toNumber(medication) || null,
                ingredients: ingredients
            }
        } else {
            medicationToAdd = {...medication}
        }

        therapyMedication.push({
            timeDoses,
            ...medicationToAdd,
            pzn: medicationToAdd.pzn || '',
            unit: medicationToAdd.unit || '',
            originallyHadDosage: !!medicationToAdd.originallyHadDosage,
            originallyHadUnit: !!medicationToAdd.originallyHadUnit
        })
    })
    return therapyMedication
}

export const getIngredientNames = (ingredients: IngredientSearchEntry[]) => {
    return ingredients.map(ing => ing.name).join('/')
}

/**
 * Append ingredient notes to be defaulted as prescription note on create
 */
export const getIngredientNotes = (ingredients: IngredientSearchEntry[]) => {
    let notes = ingredients?.map(ing => ing?.note || '') || ''
    notes = removeDuplicates(notes)
    return notes.join('\n')
}

export const getRequestedMedicationColumns = (onEdit: ((key: string) => void) | false) => {
    const columns = [
        {
            title: i18n.t('therapyTab.requestedMedication.column.medicine'),
            dataIndex: 'ingredientName',
            key: 'ingredientName',
            ellipsis: {
                showTitle: false
            },
            render: (val: string) => (
                <Tooltip placement='topLeft' title={val}>
                    {val}
                </Tooltip>
            ),
            width: '50%'
        },
        {
            title: i18n.t('therapyTab.requestedMedication.column.singleDose'),
            dataIndex: 'dosage',
            key: 'dosage',
            render: (dosage: string) => (
                <div style={styles.dosageWrapper}>
                    <span style={styles.dosageText}>{dosage}</span>
                </div>
            ),
            width: '40%'
        }
    ]
    if (onEdit) {
        columns.push({
            title: '',
            key: 'requestedTableEdit',
            // @ts-ignore - ts is stupid and expects the inferred the type of the first defined render (of timeDose)
            render: (val: any, record: RequestedPrescriptionDto) => <EditFilled style={{fontSize: 24}} onClick={() => onEdit(record.id)} />,
            align: 'right'
        })
    }
    return columns
}

export const getOtherMedicationColumns = (onEdit: ((key: string) => void) | false) => {
    const columns = [
        {
            title: i18n.t('therapyTab.otherMedication.column.medicine'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i18n.t('therapyTab.otherMedication.column.note'),
            dataIndex: 'note',
            key: 'note'
        },
        {
            title: i18n.t('therapyTab.otherMedication.column.prescribedBy'),
            dataIndex: 'prescribedBy',
            key: 'prescribedBy'
        },
        {
            title: i18n.t('therapyTab.otherMedication.column.createdBy'),
            dataIndex: 'createdBy',
            key: 'createdBy'
        }
    ]
    if (onEdit) {
        columns.push({
            title: '',
            key: 'otherTableEdit',
            // @ts-ignore - ts is stupid and expects the inferred the type of the first defined render (of timeDose)
            render: (val: any, record: OtherPrescriptionDto) => <EditFilled style={{fontSize: 24}} onClick={() => onEdit(record.id)} />,
            align: 'right'
        })
    }
    return columns
}

export const getNextTherapyStatus = (status: TherapyStatus): TherapyStatus | null => {
    switch (status) {
        case TherapyStatus.PLANNED:
            return TherapyStatus.TO_CHECK
        case TherapyStatus.TO_CHECK:
            return TherapyStatus.RELEASED
        case TherapyStatus.RELEASED:
            return TherapyStatus.CLOSED
        default:
            return null
    }
}

const allowedMultiplierEndings = ['.25', '.5', '.75']
export const checkAllowedMultiplier = (multiplier: number) => {
    if (!multiplier) {
        return true
    }
    // Bring it to a standard format to make further validation
    if (!Number.isInteger(multiplier)) {
        return allowedMultiplierEndings.some(ending => multiplier.toString().endsWith(ending))
    }
    return true
}

export const hasAnyValidMedicationDto = (meds: (MedicationSearchEntry | string)[]): boolean => {
    return meds.findIndex(it => !!it && typeof it !== 'string') !== -1
}

/**
 * Used to prepare medication dosages for being displayed.
 * If a number has only '0' as decimals then the number is converted to integer number.
 * @param dosage the current dosage value
 */
export const prepareDosageForDisplay = (dosage: number | string | undefined) => {
    if (typeof dosage === 'string') {
        dosage = toNumber(dosage)
    }
    if (dosage && dosage !== Math.round(dosage)) {
        dosage = dosage?.toFixed(2)
    } else {
        dosage = dosage?.toFixed()
    }
    const formattedDosage = toNumber(dosage)?.toLocaleString(i18n.locale) || '0'
    return prepareLocalizeDosage(formattedDosage)
}

/**
 * Format localized dosage. If the number has only one decimal then added '0'
 * @param formattedDosage the localized dosage
 */
export const prepareLocalizeDosage = (formattedDosage: string | undefined) => {
    if (!formattedDosage) {
        return formattedDosage
    }
    const dosageParts = formattedDosage.includes(',') ? formattedDosage.split(',') : formattedDosage.split('.')
    const integerPart = dosageParts[0]
    let decimalPart = dosageParts[1]
    if (!decimalPart) {
        return integerPart
    }
    if (decimalPart.length === 1) {
        decimalPart += '0'
    }
    return formattedDosage.includes(',') ? integerPart + ',' + decimalPart : integerPart + '.' + decimalPart
}

export const calculateTherapyDates = (apatStart: Date, daysString: string, duration = MAX_CYCLE_DURATION): [Date, Date] | null => {
    const [daysArray, errorMessage] = getDaysArray(daysString || '', duration)

    // console.log('## calculateTherapyDates');

    if(daysArray.length < 1 || (errorMessage ?? '') != ''){
        return null;
    }

    const firstDay = daysArray[0];
    const lastDay = daysArray[daysArray.length - 1];
    const therapyStart = addDays(apatStart, firstDay - 1);
    const therapyEnd = addDays(apatStart, lastDay - 1);

    // console.log('apatStart: ', apatStart);
    // console.log('daysString: ', daysString);
    // console.log('duration: ', duration);
    // console.log('daysArray: ', daysArray);
    // console.log('therapyStart: ', therapyStart);
    // console.log('therapyEnd: ', therapyEnd);

    return [therapyStart, therapyEnd];
}
