import React from 'react'
import {UserOutlined} from '@ant-design/icons'
import {Avatar} from 'antd'

/**
 * The component used for displaying the user icon in a custom size.
 */
const AvatarImage: React.FC<AvatarProps> = props => {
    return (
        <div style={{marginRight: 10}}>
            <Avatar size={props.size || 50} icon={<UserOutlined />} />
        </div>
    )
}

type AvatarProps = {
    size?: number
}

export default AvatarImage
