import {IPatientTherapyStore} from './utils/types'
import {makeAutoObservable} from 'mobx'
import {IPatientTherapyService} from '../service/utils/types'
import {PatientTherapyRowDto} from '../utils/types'
import {isResponseSuccessful} from '../utils/helper'
import {PatientTherapyFilter} from '../utils/enums'

class PatientTherapyStore implements IPatientTherapyStore {
    patientTherapies: PatientTherapyRowDto[]
    patientTherapyService: IPatientTherapyService

    constructor(patientTherapyService: IPatientTherapyService) {
        makeAutoObservable(this)
        this.patientTherapyService = patientTherapyService;
        this.patientTherapies = []
    }

    async cleanup() {
        this.patientTherapies = []
    }

    async initPatientTherapies(filter: PatientTherapyFilter) {
        const response = await this.patientTherapyService.getPatientTherapies(filter);
        if (!isResponseSuccessful(response)) {
            console.log("Failed to get patient therapies")
            return Promise.resolve(false);
        }
        this.setPatientTherapies(response.data)
        return Promise.resolve(true);
    }

    setPatientTherapies(therapies: PatientTherapyRowDto[]) {
        this.patientTherapies = therapies;
    }
}

export default PatientTherapyStore
