import {AxiosRequestHeaders, AxiosResponse} from 'axios'
import {IDoctorService} from './utils/types'
import {DoctorSearchEntry} from '../utils/types'
import BaseService from './base_service'
import {useAuthHeader} from '../utils/hooks/useAuthHeader'

class DoctorService extends BaseService implements IDoctorService {
    async getTherapyDoctorList(): Promise<AxiosResponse<DoctorSearchEntry[]>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.get(`/api/private/doctor/`, {
            headers: {
                ...authHeader
            }
        })
    }
}

export default DoctorService
