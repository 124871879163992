import React from 'react'
import {Color} from '../../style/custom/colors'

/**
 * The component used for rendering a warning/error message attached to a custom field.
 * Basically it will be used for displaying the validation error of the entered value.
 */
const ErrorField: React.FC<ErrorFieldProps> = (props: ErrorFieldProps) => {
    if (props.hideErrorField) {
        return <></>
    }
    if (props.warn) {
        return (
            <span
                style={{
                    color: Color.warningYellow,
                    fontSize: 13,
                    visibility: props.warn ? 'visible' : 'hidden'
                }}>
                {props.warn || '-'}
            </span>
        )
    }
    return (
        <span
            style={{
                color: Color.warningStrong,
                fontSize: 13,
                visibility: props.error ? 'visible' : 'hidden'
            }}>
            {props.error || '-'}
        </span>
    )
}

export interface ErrorFieldProps {
    error?: string
    hideErrorField?: boolean
    warn?: string
}

export default ErrorField
