import {IDoctorStore} from './utils/types'
import {DoctorSearchEntry} from '../utils/types'
import {AxiosResponse} from 'axios'
import {isResponseSuccessful} from '../utils/helper'
import {IDoctorService} from '../service/utils/types'
import {makeAutoObservable} from 'mobx'

class DoctorStore implements IDoctorStore {
    doctorService: IDoctorService
    doctors: DoctorSearchEntry[] = []

    constructor(doctorService: IDoctorService) {
        makeAutoObservable(this)
        this.doctorService = doctorService
    }

    async getTherapyDoctorList(): Promise<void> {
        const response: AxiosResponse<DoctorSearchEntry[]> = await this.doctorService.getTherapyDoctorList()
        if (!isResponseSuccessful(response)) {
            console.error('Failed to get doctors.')
            return Promise.resolve()
        }
        this.setDoctors(response.data)
        return Promise.resolve()
    }

    setDoctors(doctors: DoctorSearchEntry[]) {
        this.doctors = doctors
    }

    get getDoctors(): DoctorSearchEntry[] {
        return this.doctors
    }
}

export default DoctorStore
