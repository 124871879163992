import React from 'react'
import {Input as AntInput} from 'antd'
import {InputLabelProps} from './InputLabel'
import {sharedStyles} from '../../style/shared_styles'
import {ErrorField, InputLabel} from './index'
import {TextAreaProps} from 'antd/es/input'
import {ErrorFieldProps} from './ErrorField'
import {useDefaultValueSet} from './utils/useDefaultValueSet'

const {TextArea} = AntInput

/**
 * The custom component used for rendering a text area input.
 */
const TextAreaInput: React.FC<CustomTextAreaProps> = (props: CustomTextAreaProps) => {
    // need to do this because cannot pass custom attributes to html <input/> tag
    const {onValueChanged, label, mandatory, extraInfo, ...inputProps} = props
    useDefaultValueSet(props.value, onValueChanged, props.defaultValue, props.skipInitialize)

    const inputLabelProps: InputLabelProps = {label, mandatory, extraInfo}

    const inputStyle = props.error ? sharedStyles.border : {}

    return (
        <div style={sharedStyles.column}>
            <InputLabel {...inputLabelProps} />
            <TextArea {...inputProps} style={inputStyle} onChange={e => onValueChanged(e.target.value)} autoComplete={'nope'}/>
            <ErrorField error={props.error} />
        </div>
    )
}

interface CustomTextAreaProps extends TextAreaProps, InputLabelProps, ErrorFieldProps {
    onValueChanged: (val: any, error?: string) => void
}

export default TextAreaInput
