import React from 'react'
import {OptionItem, SideEffectType} from "../../../../utils/types";
import {Switch, Typography} from "antd";
import {CustomSelect} from "../../../common";
import {i18n} from '../../../../i18n'
import {sharedStyles} from "../../../../style/shared_styles";

/**
 * Component representing a side effect entry. Used by the ObservationDisplay component.
 */
const SideEffectEntry: React.FC<SideEffectEntryProps> = (props) => {
    const se = props.sideEffect;
    const possibleValues = se.possibleValues.filter(pv => pv.value !== 0)
    const seOptions: OptionItem[] = possibleValues.map(possVal => ({
        key: possVal.value,
        value: possVal.value,
        label: `${possVal.value} - ${possVal.label}`
    }))
    return (
        <div style={{marginTop: 10}}>
            <div style={sharedStyles.inlineContainer}>
                <Typography.Title keyboard={true} level={4}>{se.name}</Typography.Title>
                <Switch onChange={props.onSwitchChange}
                        disabled={props.disabled}
                        style={{marginLeft: 10}} checked={se.hcpEnabled} />
            </div>
            <CustomSelect key={se.id}
                          label={i18n.t('therapy.verify.sideEffects.threshold')}
                          disabled={props.disabled}
                          items={seOptions}
                          value={se.threshold} hideErrorField={true}
                          onValueChanged={value => props.onValueChanged?.(value)}/>
        </div>
    )
}

type SideEffectEntryProps = {
    sideEffect: SideEffectType
    onSwitchChange?: () => void
    onValueChanged?: (value: number) => void
    disabled?: boolean
}

export default SideEffectEntry
