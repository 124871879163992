import {generateNumberArray} from '../../../utils/helper'
import {differenceInDays, startOfDay} from 'date-fns'
import {i18n} from '../../../i18n'
import {useStores} from '../../../store'
import {TherapyGeneralInfo} from '../../../utils/types'
import {useMemo} from 'react'

export const MAX_CYCLE_DURATION = 35 // 5 weeks

export const useTherapyDetails = (): ComputedTherapyDetails => {
    const {therapyStore: {therapyComplete}} = useStores()
    return useMemo(() => {
        const therapyInfo: TherapyGeneralInfo | undefined = therapyComplete?.generalInfo
        if (!therapyInfo) {
            return {nrWeeks: [], excludedDays: [], currentDayIndex: -1, dayOfWeek: '', duration: -1}
        }
        const duration = therapyInfo.cycleDuration
        const numberOfWeeks = Math.floor(duration / 7) + (duration % 7 === 0 ? 0 : 1)
        const nrWeeks = generateNumberArray(numberOfWeeks)

        const startDate = startOfDay(therapyInfo.startDate);
        const currentDay = differenceInDays(startOfDay(new Date()), startDate) + 1
        const currentDayIndex = currentDay < 0 || currentDay > duration ? 0 : currentDay
        const dayOfWeek = i18n.t('common.days')[startDate.getDay()]

        const excludedDays: number[] = generateNumberArray(numberOfWeeks * 7 - duration, duration + 1)
        return {nrWeeks, excludedDays, currentDayIndex, dayOfWeek, duration}
    }, [therapyComplete])
}

type ComputedTherapyDetails = {
    nrWeeks: number[],
    excludedDays: number[],
    currentDayIndex: number,
    dayOfWeek: string,
    duration: number
}
