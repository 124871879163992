import React, {useEffect} from 'react'
import {useParams} from 'react-router'
import TherapySection from './components/TherapySection'
import PatientSection from './components/PatientSection'
import DiagnosisSection from './components/DiagnosisSection'
import TabView from './components/TabView'
import {styles} from './styles/PatientPage.style'
import {useStores} from '../../store'
import {Result, Spin} from 'antd'
import {observer} from 'mobx-react'
import {i18n} from '../../i18n'

/**
 * Component displaying patient, therapy and diagnose information.
 */
const PatientPage: React.FC = observer(() => {
    const {patientId, therapyId} = useParams<{patientId: string; therapyId: string}>()
    const {patientStore, therapyStore} = useStores()

    useEffect(() => {
        const userHasChanged = patientStore.patientOverview ? patientStore.patientOverview.patient.id !== patientId : false
        // delete therapies from previous user
        if (userHasChanged) {
            therapyStore.setTherapyComplete(null)
            therapyStore.setSelectedTherapyId(null)
        }
        if (therapyId) {
            therapyStore.setSelectedTherapyId(therapyId)
        }
        if (patientId) {
            console.log("Load patient")
            // in case patientOverview was undefined
            patientStore.loadPatient(patientId, userHasChanged || !patientStore.patientOverview)
        }
    }, [patientId])

    if (patientStore.patientOverview === null) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '25%'}}>
                <Spin size={'large'} />
            </div>
        )
    }

    if (patientStore.patientOverview === undefined) {
        return <Result status='404' title='404' subTitle={i18n.t('common.errors.userNotFound')} />
    }
    // edit available if doesn't exist previous secondary operations which fails
    const hasOperationsErrors: boolean | undefined | null = patientStore.patientOverview.hasOperationsErrors

    return (
        <div style={styles.pageWrapper}>
            <PatientSection patient={patientStore.patientOverview.patient} hasOperationsErrors={hasOperationsErrors} />
            {/*display banner*/}
            {hasOperationsErrors && (
                <div style={styles.operationErrorContainer}>
                    <p style={styles.operationErrorMessage}>{i18n.t('patientView.operationError.message')}</p>
                </div>
            )}
            <div style={styles.sideBySide}>
                <TherapySection patientTherapies={patientStore.patientOverview.therapies} />
                <DiagnosisSection diagnoses={patientStore.patientOverview.diagnoses} hasOperationsErrors={hasOperationsErrors} />
            </div>
            <TabView />
        </div>
    )
})

export default PatientPage
