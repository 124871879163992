import React, {useEffect, useState} from 'react'
import {ActionButtons, Input, InputLabel, LiveSearch} from '../../common'
import {DiagnoseInternalType, DiagnosisType} from '../../../utils/types'
import {i18n} from '../../../i18n'
import {useStores} from '../../../store'
import {icd10PrefillConverter, patientPrefillConverter} from '../../common/utils/searchHelper'
import {sharedStyles} from '../../../style/shared_styles'
import VSpace from '../../common/VSpace'
import {SearchTypes} from '../../../utils/enums'
import {useForm, useLoading} from '../../../utils/hooks'
import {observer} from 'mobx-react'
import {DiagnoseInternalTypeKeys, diagnoseMandatoryFields} from './utils/keys'

/**
 * Overlay or both create and edit of diagnosis component.
 */
const EditDiagnosis: React.FC<EditDiagnosisProps> = observer(props => {
    const {patientStore} = useStores()

    const [formState, , onValidateInputs, inputChangeHandler] = useForm(
        {
            patient: patientPrefillConverter(patientStore.patientOverview?.patient),
            icd10: props.diagnosis?.icdCode ? icd10PrefillConverter(props.diagnosis) : null,
            diagnosisName: props.diagnosis?.title || ''
        } as DiagnoseInternalType,
        diagnoseMandatoryFields
    )

    const [diagnosisName, setDiagnosisName] = useState<string>(props.diagnosis?.title || '')
    const [editLoading, setEditIsLoading] = useLoading(false)

    const onSave = async (createNew: boolean) => {
        const isValid = onValidateInputs()
        if (!isValid) {
            return
        }

        const dataState = formState.values as DiagnoseInternalType
        if (!dataState.patient || !dataState.diagnosisName) {
            return
        }

        const data: DiagnosisType = {
            id: props.diagnosis ? props.diagnosis.id : '',
            patientId: dataState.patient.id,
            icdCode: dataState.icd10?.code || '',
            titleOfficial: dataState.icd10?.titleOfficial || '',
            medipolisName: dataState.icd10?.medipolisName || '',
            title: diagnosisName
        }

        const isSuccess = await patientStore.createUpdateDiagnoses(data)
        setEditIsLoading(false)
        if (isSuccess && !createNew) {
            props.closeModal()
        }
    }

    useEffect(() => {
        if (formState.values.icd10 && !diagnosisName) {
            setDiagnosisName(formState.values.icd10.medipolisName ? formState.values.icd10.medipolisName : formState.values.icd10.titleOfficial)
            inputChangeHandler(
                formState.values.icd10.medipolisName ? formState.values.icd10.medipolisName : formState.values.icd10.titleOfficial,
                DiagnoseInternalTypeKeys.diagnosisName
            )
        }
    }, [formState.values.icd10])

    const editDiagnose = (val: string, error: string | undefined) => {
        setDiagnosisName(val)
        inputChangeHandler(val, DiagnoseInternalTypeKeys.diagnosisName, error)
    }

    return (
        <div>
            <LiveSearch
                label={i18n.t('diagnosis.icd.accountLabel')}
                placeholder={i18n.t('diagnosis.icd.accountPlaceholder')}
                mandatory={diagnoseMandatoryFields.includes(DiagnoseInternalTypeKeys.patient)}
                defaultValue={formState.values.patient}
                error={formState.errors[DiagnoseInternalTypeKeys.patient]}
                onValueChanged={(val, error) => {
                    inputChangeHandler(val, DiagnoseInternalTypeKeys.patient, error)
                }}
                searchType={SearchTypes.patient}
                searchThreshold={2}
            />
            <VSpace size={'3%'} />
            <LiveSearch
                label={i18n.t('diagnosis.icd.codeLabel')}
                extraInfo={i18n.t('diagnosis.icd.codeInfo')}
                placeholder={i18n.t('diagnosis.icd.codePlaceholder')}
                mandatory={diagnoseMandatoryFields.includes(DiagnoseInternalTypeKeys.icd10)}
                defaultValue={formState.values.icd10}
                error={formState.errors[DiagnoseInternalTypeKeys.icd10]}
                onValueChanged={(val, error) => {
                    inputChangeHandler(val, DiagnoseInternalTypeKeys.icd10, error)
                }}
                searchType={SearchTypes.icd10}
            />

            <div style={{...sharedStyles.column, marginBottom: '2%', marginTop: '2%'}}>
                <InputLabel label={i18n.t('diagnosis.icd.officialLabel')} extraInfo={i18n.t('diagnosis.icd.officialInfo')} />
                <span>{formState.values.icd10?.titleOfficial}</span>
            </div>
            <div style={{...sharedStyles.column, marginBottom: '3%'}}>
                <InputLabel label={i18n.t('diagnosis.icd.symedisLabel')} extraInfo={i18n.t('diagnosis.icd.symedisInfo')} />
                <span>{formState.values.icd10?.medipolisName}</span>
            </div>
            <Input
                value={diagnosisName}
                onValueChanged={editDiagnose}
                mandatory={diagnoseMandatoryFields.includes(DiagnoseInternalTypeKeys.diagnosisName)}
                error={formState.errors[DiagnoseInternalTypeKeys.diagnosisName]}
                label={i18n.t('diagnosis.icd.diagnosisLabel')}
                extraInfo={i18n.t('diagnosis.icd.diagnosisInfo')}
            />

            <ActionButtons
                onCancel={props.closeModal}
                onSave={async () => onSave(false)}
                onSaveAndNew={!props.diagnosis ? async () => onSave(true) : undefined}
                loadingSave={editLoading}
                disabledSave={editLoading}
                disabledSaveAndNew={editLoading}
            />
        </div>
    )
})

type EditDiagnosisProps = {
    diagnosis?: DiagnosisType | null
    closeModal: () => void
}

export default EditDiagnosis
