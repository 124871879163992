import {
    IDiagnoseService,
    IDoctorService,
    IObservationService,
    IPatientService,
    IPatientTherapyService,
    ISearchService,
    ITherapyService
} from './utils/types'
import PatientTherapyService from './patient_therapy_service'
import PatientService from "./patient_service";
import SearchService from './search_service'
import TherapyService from './therapy_service'
import DiagnoseService from './diagnose_service'
import SideEffectService from "./observation_service";
import DoctorService from "./doctor_service";

export type IRootService = {
    patientService: IPatientService
    patientTherapyService: IPatientTherapyService
    searchService: ISearchService
    therapyService: ITherapyService
    diagnoseService: IDiagnoseService
    observationService: IObservationService
    doctorService: IDoctorService
}

class RootService implements IRootService {
    patientService: IPatientService
    patientTherapyService: IPatientTherapyService
    searchService: ISearchService
    therapyService: ITherapyService
    diagnoseService: IDiagnoseService
    observationService: IObservationService
    doctorService: IDoctorService

    constructor() {
        this.patientService = new PatientService()
        this.patientTherapyService = new PatientTherapyService()
        this.searchService = new SearchService()
        this.therapyService = new TherapyService()
        this.diagnoseService = new DiagnoseService()
        this.observationService = new SideEffectService()
        this.doctorService = new DoctorService()
    }
}

export default RootService
