import {DoubleSelectorType, ObservationType, SideEffectType} from '../../../utils/types'
import {isNumber, toJson} from '../../../utils/helper'
import {EditMedKeys} from '../actions/utils/keys'
import {toNumber} from '../../../utils/observation_helper'

/**
 * build double selector data from current side effects
 * @param sideEffects
 */
export const getSelectorData = (sideEffects: SideEffectType[]) => {
  const selectedData: string[] = []
  const allData: DoubleSelectorType[] = []

  sideEffects.forEach(sideEffect => {
    const data: DoubleSelectorType = {
      key: sideEffect.id,
      title: sideEffect.name,
      chosen: sideEffect.hcpEnabled
    }
    if (data.chosen) {
      selectedData.push(data.key)
    }
    allData.push(data)
  })
  return {allData, selectedData}
}

/**
 * check if enabled side effect list has been changed
 * @param sideEffects
 * @param enabledSideEffects
 */
export const isSelectionChanged = (sideEffects: SideEffectType[], enabledSideEffects: string[]) => {
    return toJson(sideEffects.filter(item => item.hcpEnabled).map(item => item.id)) !== toJson(enabledSideEffects)
}


/**
 * Sorts by 2 criteria: enabled & alphabetically, with enabled being prioritised
 */
export const observationSorter = (useEnabledCriteria: boolean) => (el1: ObservationType, el2: ObservationType) => {
    if (useEnabledCriteria) {
        if (el1.hcpEnabled && !el2.hcpEnabled) {
            return -1
        }
        if (!el1.hcpEnabled && el2.hcpEnabled) {
            return 1
        }
    }
    return localeSorter(el1.name, el2.name)
}

export const localeSorter = (s1: string, s2: string) => {
    return s1.localeCompare(s2)
}

/**
 * two dosage can compute the third one,
 * function called :onBlur
 * @param id
 * @param dailyDose
 * @param originalDose
 * @param doseModification
 */
export const updateDosageLinks = (id: string, dailyDose?: any, originalDose?: any, doseModification?: number): [number, string] | undefined => {
    dailyDose = isNumber(dailyDose) ? Number(dailyDose) : toNumber(dailyDose)
    originalDose = isNumber(originalDose) ? Number(originalDose) : toNumber(originalDose)

  if (id === EditMedKeys.dailyDose && (dailyDose || dailyDose === 0)) {
    if (originalDose && originalDose !== 0) {
      const modification = Math.round((dailyDose * 100) / originalDose)
      return [modification, EditMedKeys.doseModification]
    }

    if (!originalDose && doseModification && doseModification !== 0) {
      const original = Math.round((dailyDose * 100) / doseModification)
      return [original, EditMedKeys.originalDose]
    }
  }

  if (id === EditMedKeys.doseModification && (doseModification || doseModification === 0)) {
    if (originalDose) {
      const daily = Math.round((originalDose * doseModification) / 100)
      return [daily, EditMedKeys.dailyDose]
    }

    if (!originalDose && dailyDose && doseModification !== 0) {
      const original = Math.round((dailyDose * 100) / doseModification)
      return [original, EditMedKeys.originalDose]
    }
  }

  if (id === EditMedKeys.originalDose && (originalDose || originalDose === 0)) {
    if (dailyDose && originalDose !== 0) {
      const modification = Math.round((dailyDose * 100) / originalDose)
      return [modification, EditMedKeys.doseModification]
    }

    if (!dailyDose && doseModification) {
      const daily = Math.round((originalDose * doseModification) / 100)
      return [daily, EditMedKeys.dailyDose]
    }
  }
}
