import React, {useEffect, useState} from 'react'
import {useLoading} from '../../../utils/hooks'
import {SideEffectType, UpdateObservationType, VitalDataType, VitalParameterDefinition} from '../../../utils/types'
import {useServices, useStores} from '../../../store'
import {observer} from 'mobx-react'
import {toNumber, validateVitalData} from '../../../utils/observation_helper'
import ObservationsDisplay from '../components/subcomponents/ObservationsDisplay'
import {ActionButtons} from '../../common'
import VSpace from '../../common/VSpace'

/**
 * Component used in the therapy plan verification step containing side effects and vital data in a modal.
 */
const ObservationContent: React.FC<SideEffectContentProps> = observer((props) => {
    const [loading, setLoading] = useState(false)
    const [dataLoading, setDataLoading] = useLoading(true)
    const [hasError, setHasError] = useState(false)

    const [systemSideEffects, setSystemSideEffects] = useState<SideEffectType[]>([])
    const [systemVitalData, setSystemVitalData] = useState<VitalDataType[]>([])

    const {
        therapyStore
    } = useStores()
    const {observationService} = useServices()

    useEffect(() => {
        const therapyId = therapyStore.therapyComplete?.generalInfo?.id
        if(therapyId) {
            Promise.all([
                observationService.getInitialSideEffects(therapyId),
                observationService.getInitialVitalData(therapyId)
            ]).then((response) => {
                // Response is an array of promise responses, ordered the same way they were called
                const sideEffects: SideEffectType[] = response[0].data
                setSystemSideEffects(sideEffects)
                const vitalData: VitalDataType[] = response[1].data
                setSystemVitalData(vitalData)
                setDataLoading(false)
            })
        }
    }, [])


    useEffect(() => {
        // Technically side-effects cannot be errored, so this only refers to vital data
        const invalid = systemVitalData
            .flatMap((vd) => vd.parameterDefinitions)
            .some((def) => def.error || def.maxHasError || def.minHasError)
        setHasError(invalid)
    }, [systemVitalData])

    const onSwitchChange = (type: 'sideEffects' | 'vitalData', obsId: string) => {
        const newObservations = [...(type === 'sideEffects' ? systemSideEffects : systemVitalData)].map((o) => {
            if (o.id === obsId) {
                return {...o, hcpEnabled: !o.hcpEnabled}
            }
            return o
        })
        if (type === 'sideEffects') {
            setSystemSideEffects(newObservations as SideEffectType[])
        } else {
            setSystemVitalData(newObservations as VitalDataType[])
        }
    }

    const onSideEffectChange = (seId: string, val: number) => {
        const newSideEffects = systemSideEffects.map(sideEffect => {
            if (sideEffect.id === seId) {
                return {...sideEffect, threshold: val}
            }
            return sideEffect
        })
        setSystemSideEffects(newSideEffects)
    }

    const onVitalDataChange = (vdId: string, param: VitalParameterDefinition, val: string, type: 'minThreshold' | 'maxThreshold') => {
        const newVitalData = systemVitalData.map(vd => {
            if (vd.id === vdId) {
                vd.parameterDefinitions = vd.parameterDefinitions.map(def => {
                    if (def.definitionId === param.definitionId) {
                        const error = validateVitalData(param, val, type)
                        return {...def, [type]: val, error}
                    }
                    return def
                })
                return vd
            }
            return vd
        })
        setSystemVitalData(newVitalData)
    }

    const onSave = async () => {
        setLoading(true) // don't need to set it back to false since the onSave callback destroys the modal
        const createdSideEffects: UpdateObservationType[] = systemSideEffects.filter(se => se.hcpEnabled || se.isSystem).map(se => ({
            id: se.id,
            isSystem: se.isSystem,
            hcpEnabled: se.hcpEnabled,
            alarmThreshold: {max: se.threshold}
        }))
        const createdVitalData: UpdateObservationType[] = []
        systemVitalData.filter(vd => vd.hcpEnabled || vd.isSystem).forEach(vd => {
            vd.parameterDefinitions.forEach((param) => {
                createdVitalData.push({
                    id: param.parameterId, // vitalDataId.definitionId
                    hcpEnabled: vd.hcpEnabled,
                    isSystem: vd.isSystem,
                    alarmThreshold: {
                        min: toNumber(param.minThreshold),
                        max: toNumber(param.maxThreshold),
                    }
                })
            })
        })
        await therapyStore.releaseTherapy(createdSideEffects, createdVitalData)
        props.onCloseModal?.()
    }

    return (
        <div>
            <ObservationsDisplay sideEffects={systemSideEffects} vitalData={systemVitalData}
                                 onSideEffectChange={onSideEffectChange} onVitalDataChange={onVitalDataChange}
                                 onSwitchChange={onSwitchChange}
                                 loading={dataLoading} disabled={false}/>
            <VSpace size={10}/>
            <ActionButtons onBack={props.onBack}
                           onCancel={props.onCancel}
                           onSave={onSave}
                           loadingSave={loading}
                           disabledSave={hasError}/>
        </div>
    )
})

type SideEffectContentProps = {
    onCancel: () => void
    onBack: () => void
    onSave: () => void
    onCloseModal?: () => void
}

export default ObservationContent
