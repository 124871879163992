import React from 'react'
import {styles} from '../patient/styles/PatientPage.style'
import {i18n} from '../../i18n'
import {TitleText} from '../common'

const Impressum: React.FC = () => {
    return (
        <div style={styles.pageWrapper}>
            <TitleText text={i18n.t('impressum.title')}></TitleText>
            <p/>

            <h3>{i18n.t('impressum.company.name')}</h3>
            <p>{i18n.t('impressum.company.street')}</p>
            <p>{i18n.t('impressum.company.postalCode')}</p>
            <p/>

            <h3>{i18n.t('impressum.ceo.header')}</h3>
            <p>{i18n.t('impressum.ceo.name')}</p>
            <p/>
            <p>
                {i18n.t('impressum.contact.phoneLabel')}
                <a onClick={() => (window.location.href = 'tel:' + i18n.t('impressum.contact.phone'))} target='_blank'>
                    {i18n.t('impressum.contact.phone')}
                </a>
            </p>
            <p>
                {i18n.t('impressum.contact.telefaxLabel')}
                <a onClick={() => (window.location.href = 'fax:' + i18n.t('impressum.contact.telefax'))} target='_blank'>
                    {i18n.t('impressum.contact.telefax')}
                </a>
            </p>
            <p>
                {i18n.t('impressum.contact.emailLabel')}
                <a onClick={() => (window.location.href = 'mailto:' + i18n.t('impressum.contact.email'))} target='_blank'>
                    {i18n.t('impressum.contact.email')}
                </a>
            </p>
            <p/>

            <h3>{i18n.t('impressum.register.header')}</h3>
            <p>{i18n.t('impressum.register.vatId')}</p>
            <p>{i18n.t('impressum.register.taxNr')}</p>
            <p>{i18n.t('impressum.register.hrb')}</p>
            <p>{i18n.t('impressum.register.court')}</p>
            <p/>

            <h3>{i18n.t('impressum.dataProtection.header')}</h3>
            <p>{i18n.t('impressum.dataProtection.name')}</p>
            <p>
                {i18n.t('impressum.dataProtection.phoneLabel')}
                <a onClick={() => (window.location.href = 'tel:' + i18n.t('impressum.dataProtection.phone'))} target='_blank'>
                    {i18n.t('impressum.dataProtection.phone')}
                </a>
            </p>
            <p>
                {i18n.t('impressum.dataProtection.emailLabel')}
                <a onClick={() => (window.location.href = 'mailto:' + i18n.t('impressum.dataProtection.email'))} target='_blank'>
                    {i18n.t('impressum.dataProtection.email')}
                </a>
            </p>
            <p/>

            <h3>{i18n.t('impressum.complianceOfficer.header')}</h3>
            <p>{i18n.t('impressum.complianceOfficer.name')}</p>
            <p>
                {i18n.t('impressum.complianceOfficer.phoneLabel')}
                <a onClick={() => (window.location.href = 'tel:' + i18n.t('impressum.complianceOfficer.phone'))} target='_blank'>
                    {i18n.t('impressum.complianceOfficer.phone')}
                </a>
            </p>
            <p>
                {i18n.t('impressum.complianceOfficer.emailLabel')}
                <a onClick={() => (window.location.href = 'mailto:' + i18n.t('impressum.complianceOfficer.email'))} target='_blank'>
                    {i18n.t('impressum.complianceOfficer.email')}
                </a>
            </p>
        </div>
    )
}

export default Impressum
