import React from 'react'
import {Spin, Tabs} from 'antd'
import TherapyPlanTab from './therapyplanTab/TherapyPlanTab'
import UserDataTab from './userdataTab/UserDataTab'
import {i18n} from '../../../i18n'
import {observer} from 'mobx-react'
import {useStores} from '../../../store'

/**
 * Tab Parent Component containing UserDataTab and TherapyPlanTab.
 */
const TabView: React.FC = observer(() => {
    const {
        patientStore: {patientOverview}
    } = useStores()
    const items = [
        {label: i18n.t('patientView.tabs.therapyPlan'), key: 'medication', children: <TherapyPlanTab />},
        {
            label: i18n.t('patientView.tabs.userData'),
            key: 'userdata',
            children: patientOverview ? <UserDataTab patientOverview={patientOverview} /> : <Spin />
        }
    ]
    return <Tabs defaultActiveKey='medication' items={items} />
})

export default TabView
