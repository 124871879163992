import {LocalStyleSheet} from '../utils/types'
import {Color} from './custom/colors'

const commonAttributes = {
    fontWeight: 'bold' as const,
    textTransform: 'uppercase' as const,
    margin: '10px 10px'
}

export const ButtonStyle: LocalStyleSheet = {
    primary: {
        ...commonAttributes,
        color: Color.active,
        borderColor: Color.active,
    },
    save: {
        ...commonAttributes,
        color: Color.white,
        borderColor: Color.active,
    },
    danger: {
        ...commonAttributes,
        color: Color.warningStrong,
        borderColor: Color.warningStrong,
    }
}
