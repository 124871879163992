import React, {useEffect, useState} from 'react'
import {i18n} from '../../../i18n'
import {ActionButtons, CustomModal, CustomSelect, Input, TextAreaInput, TitleText, DateInput} from '../../common'
import {sharedStyles} from '../../../style/shared_styles'
import {
    IngredientSearchEntry,
    MedicationSearchEntry,
    OptionItem,
    TherapyMedicationDto,
    TherapyPrescriptionDto,
    TherapyGeneralInfo
} from '../../../utils/types'
import {buildEnumOptions, buildSelectOptionsObject, convertFloatNumber} from '../../../utils/helper'
import {IntakeType, SearchTypes, TherapyMedicationGroup} from '../../../utils/enums'
import {useForm, useLoading} from '../../../utils/hooks'
import {ButtonStyle} from '../../../style/button'
import {Button} from 'antd'
import {getIngredientNotes, getDaysArray, calculateTherapyDates} from '../../../utils/medication_helper'
import BoxDiagram from '../components/subcomponents/BoxDiagram'
import {useTherapyDetails} from '../../common/hooks/useTherapyDetails'
import VSpace from '../../common/VSpace'
import TimeDoseTable from '../components/subcomponents/TimeDoseTable'
import {EditMedKeys, therapyMedMandatoryFields} from './utils/keys'
import {observer} from 'mobx-react'
import {useStores} from '../../../store'
import {MedicationUrls} from '../../../store/utils/types'
import MultiLiveSearch from '../../common/MultiLiveSearch'
import {Color} from '../../../style/custom/colors'
import {updateDosageLinks} from '../utils/observation_helper'
import {differenceInCalendarDays, addDays} from 'date-fns'; 
import moment from 'moment'

// Standardwerte setzen
const defaultTherapyMedication: Partial<TherapyPrescriptionDto> = {
    group: TherapyMedicationGroup.THERAPY
};

/**
 * Component responsible with editing the therapy medication in a modal.
 */
const EditTherapyMedication: React.FC<EditTherapyMedicationProps> = observer(props => {
    // Standardwerte mit ggf. schon gespeicherten mergen...
    const initialTherapyMedication: TherapyPrescriptionDto = {
        ...defaultTherapyMedication,
        ...props.med
    } as TherapyPrescriptionDto;

    const [formState, extractProps, onValidateInputs, inputChangeHandler] = useForm(initialTherapyMedication, therapyMedMandatoryFields)
    const {dailyDose, originalDose, doseModification, days, unit, therapyMedicationList, ingredients} = formState.values
    // disable flag for data which is related to selected medication
    const [disabledData, setDisabledData] = useState(false)
    const [disabledUnitSelection, setDisabledUnitSelection] = useState(false)
    // general error messages displayed in modal
    const [errorMessages, setErrorMessages] = useState<string[]>([])

    const [submitPressed, setSubmitPressed] = useState(false)
    const [deleteLoading, setDeleteLoading] = useLoading(false)

    const {duration} = useTherapyDetails()
    const {therapyStore, userStore} = useStores()

    // Flag to track if the duration is set
    const [isDurationSet, setIsDurationSet] = useState(false);

    // Eingabe Therapiestart und -Ende.
    // Soll außerhalb TherapyPrescriptionDto gespeichert werden, weil es eig. nicht ins DTO gehört.
    // Gespeichert wird in der Datenbank ja nur die Dauer als Text, z.B. "2-10".
    const [daysStartErrorMessage, setDaysStartErrorMessage] = useState<string | undefined>('');
    const [daysEndErrorMessage, setDaysEndErrorMessage] = useState<string | undefined>('');
    const [daysStart, setDaysStart] = useState<Date | null>(() => {
        // Initialize daysStart using calculateTherapyDates
        if (props.therapy && props.med) {
            const therapyDates = calculateTherapyDates(props.therapy.startDate, props.med.days, props.therapy.cycleDuration);
            return therapyDates ? therapyDates[0] : null;
        }
        return null; // Default to null if the props are not available
    });
    const [daysEnd, setDaysEnd] = useState<Date | null>(() => {
        // Initialize daysEnd using calculateTherapyDates
        if (props.therapy && props.med) {
            const therapyDates = calculateTherapyDates(props.therapy.startDate, props.med.days, props.therapy.cycleDuration);
            return therapyDates ? therapyDates[1] : null;
        }
        return null; // Default to null if the props are not available
    });

    useEffect(() => {
        if (!props.med) {
            inputChangeHandler(groupOptions[0]?.value, EditMedKeys.group)
            // das nicht, weil wir nichts voreingestellt haben wollen
            // inputChangeHandler(typeOptions[0]?.value, EditMedKeys.type)
        }
        const noOfTherapyMeds = props.med?.therapyMedicationList?.length
        if (props.med && noOfTherapyMeds && noOfTherapyMeds > 0) {
            setDisabledData(true)
        }
    }, [])

    const onDaysStartChanged = (val: Date | null, error?: string) => {
        setDaysStart(val)
        setDaysStartErrorMessage(error)
    }

    const onDaysEndChanged = (val: Date | null, error?: string) => {
        setDaysEnd(val)
        setDaysEndErrorMessage(error)
    }

    useEffect(()=>{
        setDaysBasedOnDateInput()
    }, [daysStart])

    useEffect(()=>{
        setDaysBasedOnDateInput()
    }, [daysEnd])

    // Behandlungstage als Text z.B. '1-14' setzen auf Basis eigegebener Datumsangaben
    const setDaysBasedOnDateInput = () => {
        if(daysStart && daysEnd && props.therapy.startDate){
            const startDifference = differenceInCalendarDays(daysStart, props.therapy.startDate) + 1;
            const endDifference = differenceInCalendarDays(daysEnd, daysStart) + startDifference;
            const daysString = `${startDifference}-${endDifference}`;
            // console.info(daysString)
            inputChangeHandler(daysString, EditMedKeys.days)
        } else {
            // console.info('es fehlen Daten zu Berechnung')
        }
    }

    /**
     * compute one field from other two
     * @param event
     */
    const onBlur = (event: any) => {
        const data = updateDosageLinks(event.target.id, dailyDose, originalDose, doseModification)
        if (data) {
            inputChangeHandler(data[0], data[1])
        }
    }

    const setDays = (value: string, error?: string) => {
        if (error) {
            inputChangeHandler(value, EditMedKeys.days, error)
            return
        }
        inputChangeHandler(value, EditMedKeys.days)
    }

    // Use selected medication to prefill ingredients, unit, and note
    const onMedicationSelect = (selectedMedication: MedicationSearchEntry | null) => {
        if (!selectedMedication) {
            // reset in case medication is deleted
            setDisabledData(false)
            onIngredientsChange([])
            return
        }
        const selectedIngredients = selectedMedication.ingredients

        setDisabledData(true)
        onIngredientsChange(selectedIngredients, '', false)
    }

    const onIngredientsChange = (values: IngredientSearchEntry[], error?: string, isPrefill?: boolean) => {
        inputChangeHandler(values, EditMedKeys.ingredients, error)
        if (!isPrefill) {
            // this is necessary so that on prefill the previous note is not overwritten
            inputChangeHandler(getIngredientNotes(values), EditMedKeys.note, error)
        }
    }

    const onSave = () => {
        setSubmitPressed(true)
    }

    const onDelete = async () => {
        setDeleteLoading(true)
        await therapyStore.deleteMedication(props.med!.id, MedicationUrls.THERAPY)
        props.onCancel()
        setDeleteLoading(false)
    }

    const actualSave = async (therapyMedications: TherapyMedicationDto[], errMessages: string[]) => {
        if (!onValidateInputs()) {
            setSubmitPressed(false)
            return
        }
        if (errMessages.length !== 0) {
            setErrorMessages(errMessages)
            setSubmitPressed(false)
            return
        }

        inputChangeHandler(therapyMedications, EditMedKeys.timeDoses)
        // do this also because 'inputChangeHandler' is async
        formState.values.therapyMedicationList = therapyMedications
        formState.values.dailyDose = convertFloatNumber(formState.values.dailyDose)
        formState.values.originalDose = convertFloatNumber(formState.values.originalDose)

        console.log('create or update therapy prescription', formState.values as TherapyPrescriptionDto)
        await therapyStore.createUpdateMedication(formState.values as TherapyPrescriptionDto, MedicationUrls.THERAPY)
        props.onCancel()
        setSubmitPressed(false)
    }

    // set current unit based on medication selection
    const onChangeUnit = (changedUnit: string | null) => {
        if (changedUnit) {
            inputChangeHandler(changedUnit, EditMedKeys.unit)
        } else if (!formState.values.unit) {
            // if no value is set for unit, set the default one
            inputChangeHandler(unitsOption[0]?.value, EditMedKeys.unit)
        }
        setDisabledUnitSelection(!!changedUnit)
    }

    const groupOptions: OptionItem[] = buildEnumOptions(TherapyMedicationGroup, 'therapyTab.therapyMedication.group')
    const unitsOption: OptionItem[] = buildSelectOptionsObject(userStore.getUnitMeasure)
    const typeOptions: OptionItem[] = buildEnumOptions(IntakeType, 'therapyTab.therapyMedication.type')
    return (
        <div>
            {/* ========= Medication Section ========== */}
            <TitleText text={i18n.t('editMed.medicationTitle')} />
            <div style={{display: 'flex'}}>
                <div style={{...sharedStyles.oneThirdColumn, justifyContent: 'flex-end'}}>
                    <CustomSelect 
                        label={i18n.t('editMed.type')} 
                        items={typeOptions} 
                        {...extractProps(EditMedKeys.type)} />
                    {/* <Input 
                        label={i18n.t('editMed.days')} 
                        placeholder={i18n.t('editMed.daysPlaceholder')}
                        {...extractProps(EditMedKeys.days)} /> */}
                    <DateInput
                        label={i18n.t('editMed.daysStart')}
                        max={daysEnd ?? addDays(props.therapy.startDate, props.therapy.cycleDuration - 1)}
                        min={props.therapy.startDate}
                        minMaxValidationErrorMessage={i18n.t('editMed.error.minMaxDateValidationErrorMessage', {startApat: moment(props.therapy.startDate).format('DD.MM.YYYY'), endApat: moment(addDays(props.therapy.startDate, props.therapy.cycleDuration - 1)).format('DD.MM.YYYY')})}
                        onValueChanged={onDaysStartChanged}
                        value={daysStart ?? undefined}
                        mandatory={true}
                        error={daysStartErrorMessage}
                    />
                    <DateInput
                        label={i18n.t('editMed.daysEnd')}
                        max={addDays(props.therapy.startDate, props.therapy.cycleDuration - 1)}
                        min={daysStart ?? props.therapy.startDate}
                        minMaxValidationErrorMessage={i18n.t('editMed.error.minMaxDateValidationErrorMessage', {startApat: moment(props.therapy.startDate).format('DD.MM.YYYY'), endApat: moment(addDays(props.therapy.startDate, props.therapy.cycleDuration - 1)).format('DD.MM.YYYY')})}
                        onValueChanged={onDaysEndChanged}
                        value={daysEnd ?? undefined}
                        mandatory={true}
                        error={daysEndErrorMessage}
                    />
                    <MultiLiveSearch
                        searchType={SearchTypes.ingredient}
                        searchThreshold={2}
                        mandatory={true}
                        label={i18n.t('editMed.ingredientLabel')}
                        placeholder={i18n.t('editMed.ingredientPlaceholder')}
                        defaultValues={formState.values.ingredients}
                        onValueChanged={onIngredientsChange}
                        disabled={disabledData}
                        error={formState.errors[EditMedKeys.ingredients]}
                    />
                </div>
            </div>

            {/* =========== Intake Section ============ */}
            {/* <TitleText text={i18n.t('editMed.intakeTitle')} />
            <div style={sharedStyles.inline}>
                <div style={{flex: 1, ...sharedStyles.column}}>
                </div>
                <div style={{flex: 2, ...sharedStyles.column, marginLeft: '2%'}}>
                    <BoxDiagram id={'editDiagram'} daysString={days} setDaysString={setDays} />
                    <div style={sharedStyles.inlineContainer}>
                        <Button
                            type={'default'}
                            shape={'round'}
                            style={ButtonStyle.primary}
                            onClick={() => inputChangeHandler(`1-${duration}`, EditMedKeys.days)}>
                            {i18n.t('button.selectAll')}
                        </Button>
                        <Button
                            type={'default'}
                            shape={'round'}
                            style={ButtonStyle.primary}
                            onClick={() => inputChangeHandler(undefined, EditMedKeys.days)}>
                            {i18n.t('button.selectNone')}
                        </Button>
                    </div>
                </div>
            </div> */}

            <TimeDoseTable
                unit={unit}
                dailyDose={dailyDose}
                therapyMedicationList={therapyMedicationList}
                ingredients={ingredients}
                setMedicationChanged={onMedicationSelect}
                onValidate={actualSave}
                onChangeUnit={onChangeUnit}
                triggerValidate={submitPressed}
            />

            {/* ============ Other Section ============ */}
            <TextAreaInput 
                label={i18n.t('editMed.note')} 
                {...extractProps(EditMedKeys.note)} />

            <ActionButtons
                onCancel={props.onCancel}
                renderDelete={props.med && therapyStore.canDeleteMedication}
                onDelete={onDelete}
                onSave={onSave}
                loadingDelete={deleteLoading}
                loadingSave={submitPressed}
            />

            {/* Error Modal */}
            <CustomModal open={errorMessages.length !== 0} title={i18n.t('editMed.error.title')}>
                <div style={{...sharedStyles.column, alignItems: 'center'}}>
                    <ul>
                        {errorMessages.map(msg => (
                            <li key={msg} style={{color: Color.warningStrong, fontSize: 22}}>
                                {msg}
                            </li>
                        ))}
                    </ul>
                    <Button type={'default'} shape={'round'} style={{...ButtonStyle.primary, marginTop: 25}} onClick={() => setErrorMessages([])}>
                        {i18n.t('button.cancel')}
                    </Button>
                </div>
            </CustomModal>
        </div>
    )
})

type EditTherapyMedicationProps = {
    therapy: TherapyGeneralInfo
    med?: TherapyPrescriptionDto
    onCancel: () => void
}

export default EditTherapyMedication
