/**
 * Entry point of the App. src/index.tsx
 */
import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import {i18n, loadTranslations} from './i18n'
import en_default from './i18n/translations_en.json'
import de_default from './i18n/translations_de.json'
import en_custom from './i18n/custom/custom_translations_en.json'
import de_custom from './i18n/custom/custom_translations_de.json'
import {combineJsons} from './utils/helper'

import RootApp from './RootApp'

export const publicUrl = process.env.PUBLIC_URL || ''

const en = combineJsons(en_default, en_custom)
const de = combineJsons(de_default, de_custom)

loadTranslations(en, de)

document.title = i18n.t('app.name')

ReactDOM.render(
    <React.StrictMode>
        <RootApp/>
    </React.StrictMode>,
    document.getElementById('root')
)
