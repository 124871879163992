import React, {useEffect, useState} from 'react'
import {AddressInfo, InsuranceInfo, OptionItem, PartialSuccessResponse, PatientGeneralInfo} from '../../../utils/types'
import {useForm, useLoading} from '../../../utils/hooks'
import {ActionButtons, CustomSelect, DateInput, Input, TextAreaInput, TitleText} from '../../common'
import {i18n} from '../../../i18n'
import {sharedStyles} from '../../../style/shared_styles'
import {
    addressMandatoryFields,
    ClientKeys,
    clientMandatoryFields,
    EditAddressKey,
    EditClientKey,
    EditInsuranceKey,
    insuranceMandatoryFields
} from './utils/keys'
import {buildEnumOptions, buildSelectOptions, buildSelectOptionsObject, deepCopyArray} from '../../../utils/helper'
import {Countries, InsuranceStatus, InsuranceType} from '../../../utils/enums'
import {useStores} from '../../../store'
import {observer} from 'mobx-react'
import {EMAIL_REGEX, INSURANCE_NUMBER_REGEX, PHONE_REGEX, POSTAL_CODE_REGEX} from '../../../utils/constants'
import {addYears, subYears, format} from 'date-fns'

const addGeneralMandFields: (keyof PatientGeneralInfo & string)[] = [EditClientKey.email]
const addInsMandFields: (keyof InsuranceInfo & string)[] = [ ]
const addAddrMandFields: (keyof AddressInfo & string)[] = [ ]

// Standardwerte setzen
const defaultPatient: Partial<PatientGeneralInfo> = {
    digitalSupport: true,
};

/**
 * Component used to edit patient information in a modal.
 */
const EditPatient: React.FC<EditClientProps> = observer((props: EditClientProps) => {
    const [generalMandatoryFields, setGeneralMandatoryFields] = useState(clientMandatoryFields)
    const [insMandatoryFields, setInsMandatoryFields] = useState(insuranceMandatoryFields)
    const [addrMandatoryFields, setAddrMandatoryFields] = useState(addressMandatoryFields)

    // Standardwerte verwenden
    const initialPatient: PatientGeneralInfo = {
        ...defaultPatient,
        ...props.patient
    } as PatientGeneralInfo;
    const [formState, extractProps, onValidateInputs, inputChangeHandler] = useForm(initialPatient, generalMandatoryFields)
    const [addressState, extractAddressProps, onValidateInputsAddress, inputChangeHandlerAddress] = useForm(
        props.patient?.address,
        addrMandatoryFields
    )
    const [insuranceState, extractInsuranceProps, onValidateInputsInsurance, inputChangeHandlerInsurance] = useForm(
        props.patient?.insurance,
        insMandatoryFields
    )
    const [editLoading, setEditIsLoading] = useLoading(false)

    const {patientStore, userStore} = useStores()

    useEffect(() => {
        if (props.focusInput) {
            const elem = document.getElementById(props.focusInput)
            if (elem) {
                elem.focus?.()
            }
        }
    }, [])

    const onSave = async () => {
        const isValid = onValidateInputs()
        const isValidAddress = onValidateInputsAddress()
        const isValidInsurance = onValidateInputsInsurance()

        if (!isValid || !isValidAddress || !isValidInsurance) {
            return
        }

        setEditIsLoading(true)
        let finalPatient: PatientGeneralInfo = {
            ...(formState.values as PatientGeneralInfo),
            birthdayString: formState.values.birthday ? format(formState.values.birthday, 'yyyy-MM-dd') : '',
            address: addressState.values as AddressInfo,
            insurance: insuranceState.values as InsuranceInfo,
            digitalSupport: true
        }

        // override no value from dropdowns with null instead on empty string
        finalPatient = {
            ...finalPatient,
            insurance: {
                ...finalPatient.insurance,
                status: finalPatient.insurance.status || null
            }
        }

        let isSuccess
        let isPartialSucess
        let newPatientId = ''

        // is update
        if (props.patient) {
            if(props.patient.digitalSupport && props.patient.email){
                delete finalPatient.digitalSupport
            }

            // response will be: - true/false (boolean) -> the main operation status;
            //                   - partial success dto -> a secondary operation has failed;
            const response: boolean | PartialSuccessResponse = await patientStore.updatePatient(finalPatient)

            if (response instanceof PartialSuccessResponse) {
                // close modal if a secondary operation failed.
                isPartialSucess = true
            } else {
                // close update modal if main operation was made successfully otherwise not.
                isSuccess = response
            }
        } else {
            // response will be: - patientId (string) -> the id of the created patient or empty string if main operation failed;
            //                   - partial success dto -> a secondary operation has failed;
            const response: string | PartialSuccessResponse = await patientStore.createPatient(finalPatient)

            if (response instanceof PartialSuccessResponse) {
                newPatientId = response.id

                // close modal when a secondary operation has failed (patient was created)
                isPartialSucess = true
            } else {
                newPatientId = response
                isSuccess = !!response
            }
        }

        setEditIsLoading(false)

        if (isSuccess || isPartialSucess) {
            props.onSuccess?.(newPatientId)
            props.closeModal()
        }
    }

    const genderOptions: OptionItem[] = buildSelectOptionsObject(userStore.getGender)
    const insuranceStatus: OptionItem[] = buildEnumOptions(InsuranceStatus, 'insurance.status')
    const insuranceType: OptionItem[] = buildEnumOptions(InsuranceType, 'insurance.type')
    const titleOptions: OptionItem[] = buildSelectOptions(userStore.getClientTitlesList)
    const formOfAddressOptions: OptionItem[] = buildSelectOptions(userStore.getFormOfAddress)
    const federalStatesOptions: OptionItem[] = buildSelectOptions(userStore.getFederalStates)
    const countryOptions: OptionItem[] = buildEnumOptions(Countries, 'patient.address.country')

    // if create prefill dropdowns
    useEffect(() => {
        if (!props.patient) {
            inputChangeHandlerAddress(federalStatesOptions[0]?.value, EditAddressKey.state)
            inputChangeHandlerAddress(countryOptions[0]?.value, EditAddressKey.country)
        }
    }, [])

    // toggle mandatory fields when digitalSupport is update
    useEffect(() => {
        if (formState.values.digitalSupport) {
            setGeneralMandatoryFields([...generalMandatoryFields, ...addGeneralMandFields])
            setInsMandatoryFields([...insMandatoryFields, ...addInsMandFields])
        } else {
            setGeneralMandatoryFields(deepCopyArray(generalMandatoryFields).filter(it => !addGeneralMandFields.includes(it)))
            setInsMandatoryFields(deepCopyArray(insMandatoryFields).filter(it => !addInsMandFields.includes(it)))

            // remove error messages
            addGeneralMandFields.forEach(field => {
                inputChangeHandler(formState.values[field], field, '')
            })
            addInsMandFields.forEach(field => {
                inputChangeHandlerInsurance(insuranceState.values[field], field, '')
            })
        }
        // in editMode if digitalSupport is disabled/enabled => fill/hide the generated email
        if (props.patient && !props.patient.digitalSupport && props.patient.email) {
            inputChangeHandler(formState.values.digitalSupport ? '' : props.patient.email, EditClientKey.email)
        }
    }, [formState.values.digitalSupport])

    const hasDigitalSupport = !!props.patient?.digitalSupport

    return (
        <div>
            <TitleText text={i18n.t('userDataTab.header.general')} />
            <div style={{display: 'flex'}}>
                <div style={sharedStyles.leftColumn}>
                    <CustomSelect
                        label={i18n.t('userDataTab.general.salutation')}
                        items={formOfAddressOptions}
                        {...extractProps(EditClientKey.formOfAddress)}
                    />
                    <Input label={i18n.t('userDataTab.general.firstName')} {...extractProps(EditClientKey.firstName)} />
                    <DateInput
                        label={i18n.t('userDataTab.general.birthday')}
                        max={new Date()}
                        min={subYears(new Date(), 130)}
                        defaultPickerValue={addYears(new Date(), -30)}
                        {...extractProps(EditClientKey.birthday)}
                    />
                </div>
                <div style={sharedStyles.rightColumn}>
                    <CustomSelect
                        label={i18n.t('userDataTab.general.title')}
                        nullOption={true}
                        items={titleOptions}
                        {...extractProps(EditClientKey.academicTitle)}
                    />
                    <Input label={i18n.t('userDataTab.general.lastName')} {...extractProps(EditClientKey.lastName)} />
                </div>
            </div>

            <TitleText text={i18n.t('userDataTab.header.contact')} />
            <Input label={i18n.t('userDataTab.contact.street')} {...extractAddressProps(EditAddressKey.street)} />
            <div style={{display: 'flex'}}>
                <div style={sharedStyles.leftColumn}>
                    <Input
                        label={i18n.t('userDataTab.contact.postalCode')}
                        regex={POSTAL_CODE_REGEX}
                        {...extractAddressProps(EditAddressKey.postalCode)}
                    />
                </div>
                <div style={sharedStyles.rightColumn}>
                    <Input label={i18n.t('userDataTab.contact.city')} {...extractAddressProps(EditAddressKey.city)} />
                </div>
            </div>
            <Input
                label={i18n.t('userDataTab.contact.email.title')}
                extraInfo={i18n.t('userDataTab.contact.email.extra')}
                regex={EMAIL_REGEX}
                {...extractProps(EditClientKey.email)}
                />
            <div style={{display: 'flex'}}>
                <div style={sharedStyles.leftColumn}>
                    <Input 
                        label={i18n.t('userDataTab.contact.phone')} 
                        regex={PHONE_REGEX}
                        {...extractProps(EditClientKey.phone)} 
                        pixelAlignTop={true}/>
                </div>
                <div style={sharedStyles.rightColumn}>
                    <Input
                        label={i18n.t('userDataTab.contact.cellphone')}
                        regex={PHONE_REGEX}
                        {...extractProps(EditClientKey.cellphone)}
                        pixelAlignTop={true}
                    />
                </div>
            </div>
            <TextAreaInput
                label={i18n.t('userDataTab.extraInfo.note.title')}
                extraInfo={i18n.t('userDataTab.extraInfo.note.extra')}
                {...extractProps(EditClientKey.note)}
            />

            <ActionButtons onCancel={props.closeModal} onSave={async () => onSave()} loadingSave={editLoading} />
        </div>
    )
})

type EditClientProps = {
    patient?: PatientGeneralInfo
    closeModal: () => void
    onSuccess?: (newPatientId: string) => void
    focusInput?: ClientKeys
}

export default EditPatient
