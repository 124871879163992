import React, {useCallback, useState} from 'react'
import {i18n} from '../../../../i18n'
import {CustomModal, TitleText} from '../../../common'
import {Button, Table, Typography} from 'antd'
import {IngredientSearchEntry, TherapyPrescriptionDto, TherapyGeneralInfo} from '../../../../utils/types'
import {getIngredientNames, getTherapyMedicationColumns} from '../../../../utils/medication_helper'
import {sharedStyles} from '../../../../style/shared_styles'
import {styles} from '../../styles/TherapyMedicationTable.style'
import {ButtonStyle} from '../../../../style/button'
import EditTherapyMedication from '../../actions/EditTherapyMedication'
import BoxDiagram from '../subcomponents/BoxDiagram'
import {useTherapyDetails} from '../../../common/hooks/useTherapyDetails'
import {observer} from 'mobx-react'
import {useStores} from '../../../../store'
import {ConfigTypes} from '../../../../utils/enums'

const {Text} = Typography

/**
 * Component displaying the therapy medication data of a therapy plan.
 */
const TherapyMedicationTable: React.FC<TherapyMedicationTableProps> = observer(props => {
    const [prefilledMedication, setPrefilledMedication] = useState<TherapyPrescriptionDto | undefined>()
    const [modalVisible, setModalVisible] = useState(false)
    const {nrWeeks} = useTherapyDetails()

    const {userStore} = useStores()

    const closeModal = () => {
        setModalVisible(false)
        setPrefilledMedication(undefined)
    }
    const openModal = () => setModalVisible(true)

    const onEdit = (medication: TherapyPrescriptionDto) => {
        setPrefilledMedication(medication)
        openModal()
    }

    const getWeekHeader = () => {
        const weekTitle = [
            <span key={'header0'} style={{flex: 1}}>
                {i18n.t('therapyTab.therapyMedication.diagram.text')}
            </span>
        ]
        const weekHeaders = nrWeeks.map(nbr => (
            <div key={`header${nbr + 1}`} style={{flex: 1, borderRight: 'solid'}}>
                <span style={{paddingLeft: '4%'}}>
                    {i18n.t('therapyTab.therapyMedication.diagram.week')} {nbr + 1}
                </span>
            </div>
        ))
        return weekTitle.concat(weekHeaders)
    }

    const columns = getTherapyMedicationColumns(props.actionsAvailable && onEdit)
    const dataSource = props.meds.map(med => ({
        key: med.id,
        ingredientName: getIngredientNames(med.ingredients),
        timeDosesWithUnit: med.therapyMedicationList,
        ...med,
        unit: userStore.getI18nText(ConfigTypes.UNIT_MEASURE, med.unit),
        therapy: props.therapy
    }))

    const getIngredientName = useCallback(
        (ingredients: IngredientSearchEntry[]) => {
            return getIngredientNames(ingredients)
        },
        [props.meds]
    )

    return (
        <div>
            <div style={{...sharedStyles.inlineContainer, marginTop: '2%'}}>
                <TitleText text={i18n.t('therapyTab.therapyMedication.title')} />
                {props.actionsAvailable && (
                    <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={openModal}>
                        {i18n.t('button.create')}
                    </Button>
                )}
            </div>

            <Table  dataSource={dataSource} 
                    columns={columns} 
                    pagination={false} 
                    rowClassName={'disable-hover'}
                    locale={{ emptyText: i18n.t('therapyTab.therapyMedication.noData') }} />

            <CustomModal title={i18n.t(`editMed.title.therapy.${prefilledMedication ? 'edit' : 'new'}`)} open={modalVisible} width={'75%'}>
                <EditTherapyMedication therapy={props.therapy} med={prefilledMedication} onCancel={closeModal} />
            </CustomModal>
        </div>
    )
})

type TherapyMedicationTableProps = {
    therapy: TherapyGeneralInfo
    meds: TherapyPrescriptionDto[]
    actionsAvailable: boolean
}

export default TherapyMedicationTable
