import {HttpClientData} from './utils/types'
import {AxiosInstance, AxiosRequestConfig} from 'axios'
import {createClient} from './utils/helper'
import {useLogout} from '../store'
import {transformDateValues} from '../utils/helper'
import {onNotificationError, onNotificationSuccess, onNotificationWarning} from './utils/notificationHelper'
import {PARTIAL_SUCCESS_RESPONSE_STATUS_CODE} from '../utils/constants'
import {i18n} from '../i18n'

class BaseService implements HttpClientData {
    client: AxiosInstance

    constructor() {
        this.client = createClient()
        // @ts-ignore
        this.client.defaults.transformResponse = this.client.defaults.transformResponse.concat((data: any) => {
            return transformDateValues(data)
        })
        this.client.interceptors.response.use(this.onSuccessHandler, this.errorHandler)
        this.client.interceptors.request.use((requestConfig: AxiosRequestConfig) => ({
            ...requestConfig,
            headers: {
                ...requestConfig.headers,
                'Accept-Language': i18n.locale
            }
        }))
    }

    private errorHandler = async (error: any) => {
        if (error?.response && (error.response.status === 401 || error.response.status === 403)) {
            useLogout?.()
        }
        onNotificationError({
            description: error?.response?.data?.errorMessage || error?.response?.statusText
        })
        return error
    }

    private onSuccessHandler = (response: any) => {
        const method = response?.config?.method
        if (method && (method === 'post' || method === 'patch' || method === 'put')) {
            onNotificationSuccess({
                description: i18n.t(`notification.success.${method === 'post' ? 'create' : 'update'}`)
            })
        }

        // check if a secondary operation failed
        if (response?.status === PARTIAL_SUCCESS_RESPONSE_STATUS_CODE) {
            onNotificationWarning({description: response.data.message})
        }
        return response
    }
}

export default BaseService
