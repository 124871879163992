import React from 'react'
import {Checkbox, Input as AntInput, InputProps as AntInputProps} from 'antd'
import {ErrorField, InputLabel} from './index'
import {InputLabelProps} from './InputLabel'
import {sharedStyles} from '../../style/shared_styles'
import {ErrorFieldProps} from './ErrorField'

/**
 * The custom component used for rendering an input or a checkbox.
 * Will be used in all components for rendering all the inputs in all the forms.
 * The entered input value will be validated and a message error will be attached to it, if exist.
 */
const BooleanInput: React.FC<BooleanInputProps> = (props: BooleanInputProps) => {
    // need to do this because cannot pass custom attributes to html <input/> tag
    const {
        onValueChanged,
        label,
        mandatory,
        extraInfo,
        hideErrorField,
        skipInitialize,
        ...inputProps
    } = props
    // useDefaultValueSet(props.value, onValueChanged, props.defaultValue, skipInitialize)

    const inputLabelProps: InputLabelProps = {label, mandatory, extraInfo}

    const inputStyle = props.error ? sharedStyles.border : {width: '8%'}

    const divStyle: React.CSSProperties = {
        ...sharedStyles.column,
        marginTop: 0,
        marginBottom: 0,
        // display: isCheckbox ? 'flex' : 'block',    // Flexbox applied when it's a checkbox
        // alignItems: isCheckbox ? 'center' : 'initial', // Align items center for checkbox
    }

    // delete error when input value is changed
    const onChange = (value: any) => {
        onValueChanged(value)
    }


    return (
        <div style={divStyle}>
            <div style={{ display: 'flex'}}>
                <Checkbox {...inputProps} 
                        style={inputStyle} 
                        onChange={e => onChange(e.target.checked)} 
                        checked={props.checked === true} />
                <div style={{ marginLeft: '8px' }}>
                    <InputLabel {...inputLabelProps} />
                </div>
            </div>
            <ErrorField error={props.error} hideErrorField={hideErrorField} />
        </div>
    )
}

interface BooleanInputProps extends InputLabelProps, ErrorFieldProps {
    onValueChanged: (val: boolean, error?: string) => void
    checked?: boolean
}

export default BooleanInput
