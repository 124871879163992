import React, {CSSProperties} from 'react'
import {Color} from "../../style/custom/colors";
import {Input} from "./index";

/**
 * The custom component used for rendering an div empty.
 * The div can contains or not an input which will be hidden.
 * Basically this component will be used as a helper for improving the style.
 */
const EmptyDiv: React.FC<EmptyDivProps> = (props) => {
    return (
        <div style={{...props.style, visibility: 'hidden'}}>
            {props.inputHeight ?  <Input onValueChanged={() => {/**/}} hideErrorField={true}/> :
                <span style={{color: Color.transparent, userSelect: 'none', WebkitUserSelect: 'none'}}>.</span>
            }
        </div>
    )
}

type EmptyDivProps = {
    style?: CSSProperties
    inputHeight?: boolean
}

export default EmptyDiv
