import {IPatientStore, IUIStore} from './utils/types'
import {makeAutoObservable} from 'mobx'
import {IDiagnoseService, IPatientService, ISearchService} from '../service/utils/types'
import {DiagnosisType, PartialSuccessResponse, PatientGeneralInfo, PatientOverviewType} from '../utils/types'
import {isResponsePartialSuccessful, isResponseSuccessful} from '../utils/helper'
import {AxiosResponse} from 'axios'

class PatientStore implements IPatientStore {
    patientService: IPatientService
    searchService: ISearchService
    diagnoseService: IDiagnoseService
    patientOverview: PatientOverviewType | null | undefined = null
    uiStore: IUIStore

    constructor(patientService: IPatientService, searchService: ISearchService, diagnoseService: IDiagnoseService, uiStore: IUIStore) {
        makeAutoObservable(this)
        this.patientService = patientService
        this.searchService = searchService
        this.diagnoseService = diagnoseService
        this.uiStore = uiStore
    }

    async cleanup() {
        this.patientOverview = null
    }

    async loadPatient(id: string, deleteCurrent?: boolean): Promise<void> {
        if (deleteCurrent) {
            this.patientOverview = null
        }
        const response: AxiosResponse<PatientOverviewType> = await this.patientService.getPatient(id)
        if (!isResponseSuccessful(response)) {
            console.error('Failed to get patient with id: ' + id + '. Reason: ' + response)
            this.uiStore.setShowLoading(false)
            this.setPatientOverview(undefined)
            return
        }
        this.setPatientOverview(response.data)
        this.uiStore.setShowLoading(false)
    }

    async updatePatient(patient: PatientGeneralInfo): Promise<boolean | PartialSuccessResponse> {
        const response: AxiosResponse<string | PartialSuccessResponse> = await this.patientService.updatePatient(patient)
        if (!isResponseSuccessful(response)) {
            console.error(`Failed to update patient: ${patient.id}`)
            return Promise.resolve(false)
        }

        // check if the response is partial success. If it is then a partial success dto was returned.
        if (isResponsePartialSuccessful(response)) {
            console.warn('A secondary operation has failed')
            const responseData: PartialSuccessResponse = response.data as PartialSuccessResponse

            await this.refreshPatient()
            return Promise.resolve(new PartialSuccessResponse(responseData.id, responseData.message))
        }

        await this.refreshPatient()

        return Promise.resolve(true)
    }

    async createPatient(patient: PatientGeneralInfo): Promise<string | PartialSuccessResponse> {
        const response: AxiosResponse<string | PartialSuccessResponse> = await this.patientService.createPatient(patient)
        if (!isResponseSuccessful(response)) {
            console.error(`Failed to create patient: ${patient.email}`)
            return Promise.resolve('')
        }

        // check if the response is partial success. If it is then a partial success dto was returned.
        if (isResponsePartialSuccessful(response)) {
            console.warn('A secondary operation has failed')
            const responseData: PartialSuccessResponse = response.data as PartialSuccessResponse
            return Promise.resolve(new PartialSuccessResponse(responseData.id, responseData.message))
        }

        return Promise.resolve(response.data)
    }

    async createUpdateDiagnoses(diagnoses: DiagnosisType): Promise<boolean> {
        const response: AxiosResponse<void> = await this.diagnoseService.createUpdateDiagnose(diagnoses)
        if (!isResponseSuccessful(response)) {
            console.error(`Failed to create diagnose`)
            return Promise.resolve(false)
        }
        await this.refreshPatient()
        return Promise.resolve(true)
    }

    async refreshPatient(): Promise<void> {
        if (!this.patientOverview) {
            return
        }
        this.uiStore.setShowLoading(true)
        this.loadPatient(this.patientOverview.patient.id)
    }

    setPatientOverview(patient: PatientOverviewType | null | undefined) {
        this.patientOverview = patient
    }
}

export default PatientStore
