import {IObservationStore, IPatientStore, ITherapyStore, IUIStore, MedicationUrls} from './utils/types'
import {
    CompleteTherapyDto,
    OtherPrescriptionDto,
    RequestedPrescriptionDto,
    TherapyGeneralInfo,
    TherapyPrescriptionDto,
    UpdateObservationType
} from '../utils/types'
import {ITherapyService} from '../service/utils/types'
import {autorun, IReactionDisposer, makeAutoObservable} from 'mobx'
import {AxiosResponse} from 'axios'
import {fromStorage, isResponseSuccessful, toStorage} from '../utils/helper'
import {StorageKey, TherapyStatus} from '../utils/enums'
import {onNotificationError} from '../service/utils/notificationHelper'
import {i18n} from '../i18n'

class TherapyStore implements ITherapyStore {
    patientStore: IPatientStore
    sideEffectsStore: IObservationStore
    therapyService: ITherapyService
    therapyComplete: CompleteTherapyDto | null = null
    selectedTherapyId: string | null = null // null means latest
    autorunCleaners?: IReactionDisposer[] = []
    uiStore: IUIStore

    constructor(therapyService: ITherapyService, patientStore: IPatientStore, sideEffectsStore: IObservationStore, uiStore: IUIStore) {
        makeAutoObservable(this)
        this.patientStore = patientStore
        this.therapyService = therapyService
        this.sideEffectsStore = sideEffectsStore
        this.selectedTherapyId = fromStorage(StorageKey.therapyId)
        this.uiStore = uiStore
    }

    async getTherapyById(id: string, showLoading?: boolean): Promise<void> {
        if (showLoading) {
            this.uiStore.setShowLoading(true)
        }
        const response: AxiosResponse<CompleteTherapyDto> = await this.therapyService.getTherapyById(id)
        if (!isResponseSuccessful(response)) {
            console.error('Failed to get patient with id: ' + id + '. Reason: ' + response)
            this.uiStore.setShowLoading(false)
            return Promise.resolve()
        }
        this.setTherapyComplete(response.data)
        this.uiStore.setShowLoading(false)
        return Promise.resolve()
    }

    async createUpdateTherapy(therapy: TherapyGeneralInfo, therapyCloneId?: string): Promise<boolean> {
        const response: AxiosResponse<string> = await this.therapyService.createUpdateTherapy(therapy, therapyCloneId)
        if (!isResponseSuccessful(response)) {
            console.error('Failed to update / create therapy')
            return Promise.resolve(false)
        }

        // get all therapies if update or create (if update need to update the values from  "Select Therapy Plan" too not just the entire therapy)
        await this.patientStore.refreshPatient()
        if (therapy.id) {
            // refresh current therapy if update
            await this.refreshTherapy()
        } else {
            this.setSelectedTherapyId(response.data || null)
        }
        return Promise.resolve(true)
    }

    async createUpdateMedication(
        med: TherapyPrescriptionDto | RequestedPrescriptionDto | OtherPrescriptionDto,
        type: MedicationUrls
    ): Promise<boolean> {
        const response: AxiosResponse<void> = await this.therapyService.createUpdateMedication(this.therapyId, med, type)
        if (!isResponseSuccessful(response)) {
            console.error(`Failed to create/update ${type} medication: ${response}`)
            return Promise.resolve(false)
        }
        await this.refreshTherapy()
        return Promise.resolve(true)
    }

    async deleteMedication(therapyMedId: string, type: MedicationUrls): Promise<void> {
        const response: AxiosResponse<void> = await this.therapyService.deleteMedication(this.therapyId, therapyMedId, type)
        if (!isResponseSuccessful(response)) {
            console.error(`Failed to delete ${type}: ${response}`)
            return Promise.reject()
        }
        await this.refreshTherapy()
        return Promise.resolve()
    }

    async refreshTherapy(): Promise<void> {
        if (!this.therapyComplete) {
            return
        }
        this.uiStore.setShowLoading(true)
        this.getTherapyById(this.therapyComplete.generalInfo.id)
    }

    setTherapyComplete(therapy: CompleteTherapyDto | null) {
        if (!therapy) {
            this.sideEffectsStore.resetObservations()
        }
        this.therapyComplete = therapy
        if (this.showObservations) {
            this.sideEffectsStore.refreshObservations()
        }
    }

    setSelectedTherapyId(therapyId: string | null) {
        this.selectedTherapyId = therapyId
    }

    get therapyId(): string {
        return this.therapyComplete?.generalInfo.id || ''
    }

    get canDeleteMedication() {
        const status = this.therapyComplete?.generalInfo.status
        return status === TherapyStatus.PLANNED || status === TherapyStatus.TO_CHECK
    }

    get canUpdateMedication() {
        const status = this.therapyComplete?.generalInfo.status
        return status === TherapyStatus.PLANNED || status === TherapyStatus.TO_CHECK // || status === TherapyStatus.RELEASED
    }

    get showObservations() {
        const status = this.therapyComplete?.generalInfo.status
        return status === TherapyStatus.RELEASED || status === TherapyStatus.CLOSED
    }

    get hasTherapyMedication() {
        return !!(this.therapyComplete?.therapyMedication.length ||
            this.therapyComplete?.requestedMedication.length ||
            this.therapyComplete?.otherMedication.length)
    }

    initialize() {
        const disposer = autorun(() => {
            toStorage(StorageKey.therapyId, this.selectedTherapyId)
        })
        this.autorunCleaners = [disposer]
    }

    async cleanup(): Promise<void> {
        this.therapyComplete = null
        this.selectedTherapyId = null
        this.autorunCleaners?.forEach(disposer => disposer())
        return Promise.resolve()
    }

    async generateReport(therapyId: string, patientId: string): Promise<void> {
        const response: AxiosResponse<string> = await this.therapyService.generateReport(therapyId, patientId)
        if (!isResponseSuccessful(response)) {
            console.error(`Failed to generate pdf report for therapyId: ${therapyId} and patientId: ${patientId}. Reason: ${response}`)
            this.uiStore.setShowLoading(false)
            return Promise.resolve()
        }
        if (response.data) {
            const filename = response.headers['content-disposition'].split('filename=')[1];
            const blobFile = new File([response.data], filename, {type: 'application/pdf'})
            window.open(window.URL.createObjectURL(blobFile))
        } else {
            onNotificationError({description: i18n.t('common.errors.reportGeneration')})
        }
        this.uiStore.setShowLoading(false)
        return Promise.resolve()
    }

    async exportTherapies(patientId: string): Promise<boolean> {
        const response: AxiosResponse<void> = await this.therapyService.exportTherapies(patientId)
        if (!isResponseSuccessful(response)) {
            console.error(`Failed to export therapies, reason: ${response}`)
            return Promise.resolve(false)
        }
        await this.patientStore.refreshPatient()
        return Promise.resolve(true)
    }

    async requestToCheckTherapy(): Promise<void> {
        const response: AxiosResponse<void> = await this.therapyService.requestToCheckTherapy(this.therapyId)
        if (!isResponseSuccessful(response) || !this.therapyComplete) {
            console.error(`Failed to update therapy status to "TO_CHECK": ${response}`)
            return Promise.reject()
        }
        await this.patientStore.refreshPatient()
        return Promise.resolve()
    }

    async closeTherapy(): Promise<void> {
        const response: AxiosResponse<void> = await this.therapyService.closeTherapy(this.therapyId)
        if (!isResponseSuccessful(response) || !this.therapyComplete) {
            console.error(`Failed to update therapy status to "CLOSE": ${response}`)
            return Promise.reject()
        }
        await this.patientStore.refreshPatient()
        return Promise.resolve()
    }

    async releaseTherapy(sideEffects: UpdateObservationType[], vitalData: UpdateObservationType[]): Promise<void> {
        const response: AxiosResponse<void> = await this.therapyService.releaseTherapy(this.therapyId, sideEffects, vitalData)
        if (!isResponseSuccessful(response) || !this.therapyComplete) {
            console.error(`Failed to update therapy status to "RELEASED": ${response}`)
            return Promise.reject()
        }
        await this.patientStore.refreshPatient()
        return Promise.resolve()
    }
}

export default TherapyStore
