/**
 * Helper for UI
 */
import styles from "../components/patient/styles/UserDataTab.style";
import React from "react";
import { AddressInfo } from "./types";

export const formatAddress = (address: AddressInfo) => {
  if (!address) {
    return undefined;
  }
  return (
    <div>
      <span style={styles.addressLine}>{address.street}</span>
      <span style={styles.addressLine}>{address.postalCode}</span>
      <span style={styles.addressLine}>{`${address.city}, ${address.country}`}</span>
    </div>
  )
}
