import {LocalStyleSheet} from '../../../utils/types'
import {sharedStyles} from "../../../style/shared_styles";

export const elemMargin = 6;
export const styles: LocalStyleSheet = {
    columnHeader: {
        marginBottom: elemMargin
    },
    column1: {
        ...sharedStyles.column,
        flex: 0.3,
        marginRight: elemMargin,
    },
    column2: {
        ...sharedStyles.column,
        flex: 2
    },
    column3: {
        ...sharedStyles.column,
        flex: 1,
        marginRight: elemMargin,
        marginLeft: elemMargin,
        flexShrink: 0,
    },
    column4: {
        ...sharedStyles.column,
        flex: 1,
        flexShrink: 0,
    },
    column5: {
        ...sharedStyles.column,
        display: 'flex',
        flex: 2,
        marginLeft: elemMargin
    },
    center: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default styles
