import React, { CSSProperties } from 'react'
import {sharedStyles} from '../../../../style/shared_styles'
import styles from '../../styles/TimeDoseTable.style'
import {InputLabel} from '../../../common/index'

const EmptyColumnHeader: React.FC<EmptyColumnHeaderProps> = (props) => {
    return (
        <div style={{ ...sharedStyles.inlineContainer, ...styles.columnHeader, visibility: 'hidden' }}>
            <InputLabel label={'col4'} />
        </div>
    )
}

type EmptyColumnHeaderProps = {
}

export default EmptyColumnHeader
