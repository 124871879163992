import React, {ReactNode, useMemo} from 'react'
import {OptionItem} from '../../utils/types'
import {Select} from 'antd'
import {InputLabelProps} from './InputLabel'
import {ErrorField, InputLabel} from './index'
import {i18n} from '../../i18n'
import {ErrorFieldProps} from './ErrorField'
import {sharedStyles} from '../../style/shared_styles'

const {Option} = Select

/**
 * Component used for rendering a custom select.
 * This select is a generic one and will be used in all the components in which the selection of a value is needed.
 */
const CustomSelect: React.FC<CustomSelectProps> = (props: CustomSelectProps) => {
    const onChange = (value: any) => {
        const errorMessage = props.mandatory && !value ? i18n.t('common.errors.empty') : ''
        props.onValueChanged(value, errorMessage)
    }

    const options = useMemo(() => {
        const selectOptions: ReactNode[] = []

        if (props.nullOption) {
            selectOptions.push(
                <Option key={'no_option'} value={''}>
                    {i18n.t('common.dropdown.empty')}
                </Option>
            )
        }

        props.items.forEach(item => {
            selectOptions.push(
                <Option key={item.key} value={item.value}>
                    {item.label}
                </Option>
            )
        })

        return selectOptions
    }, [props.items])

    const inputStyle = props.error ? sharedStyles.border : {}
    const {id, hideErrorField, ...labelProps} = props

    return (
        <div style={{width: '100%'}}>
            <InputLabel {...labelProps} />
            <Select
                // fix wrong positioning on scroll
                getPopupContainer={trigger => trigger.parentElement}
                id={id}
                showSearch={props.enableSearch}
                style={{...inputStyle, width: '100%'}}
                onChange={onChange}
                dropdownMatchSelectWidth={false}
                defaultActiveFirstOption={true}
                value={props.value}
                defaultValue={props.defaultValue}
                disabled={props.disabled}>
                {options}
            </Select>
            <ErrorField error={props.error} hideErrorField={hideErrorField} />
        </div>
    )
}

interface CustomSelectProps extends InputLabelProps, ErrorFieldProps {
    items: OptionItem[]
    value?: any
    onValueChanged: (value: any, error?: string) => void
    nullOption?: boolean
    defaultValue?: any
    disabled?: boolean
    id?: string
    enableSearch?: boolean
}

export default CustomSelect
