import React, {useState} from 'react'
import {sharedStyles} from '../../../style/shared_styles'
import {styles} from '../styles/TherapySection.style'
import {i18n} from '../../../i18n'
import {DiagnosisType} from '../../../utils/types'
import DiagnosisEntry from './subcomponents/DiagnosisEntry'
import {Button} from 'antd'
import {ButtonStyle} from '../../../style/button'
import {CustomModal} from '../../common'
import EditDiagnosis from '../actions/EditDiagnosis'

/**
 * Component containing a patient's diagnosis data.
 */
const DiagnosisSection: React.FC<DiagnosisSectionProps> = props => {
    const [prefilledDiagnosis, setPrefilledDiagnosis] = useState<DiagnosisType | null>(null)
    const [modalVisible, setModalVisible] = useState(false)

    const closeModal = () => {
        setModalVisible(false)
        setPrefilledDiagnosis(null)
    }
    const openModal = () => setModalVisible(true)

    const onEdit = (diagnosis: DiagnosisType) => {
        setPrefilledDiagnosis(diagnosis)
        openModal()
    }

    return (
        <div style={{...sharedStyles.sectionWrapper, justifyContent: 'space-between'}}>
            <h3 style={styles.padding}>{i18n.t('diagnosis.title')}</h3>

            {props.diagnoses.length === 0 && <p>{i18n.t('common.errors.noData')}</p>}

            {props.diagnoses.map((diagnosis: DiagnosisType) => (
                <DiagnosisEntry key={diagnosis.id} diagnosis={diagnosis} onEdit={onEdit} hasOperationsErrors={props.hasOperationsErrors} />
            ))}
            <div style={{...sharedStyles.inlineContainer, paddingTop: 10}}>
                {/*edit action will be available only if current patient doesn't have secondary operations which have failed...*/}
                {!props.hasOperationsErrors && (
                    <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={openModal}>
                        {i18n.t('diagnosis.newDiagnosis')}
                    </Button>
                )}
            </div>

            <CustomModal title={i18n.t(`diagnosis.${prefilledDiagnosis ? 'edit' : 'create'}`)} open={modalVisible}>
                <EditDiagnosis closeModal={closeModal} diagnosis={prefilledDiagnosis} />
            </CustomModal>
        </div>
    )
}

type DiagnosisSectionProps = {
    diagnoses: DiagnosisType[]
    hasOperationsErrors?: boolean | undefined | null
}

export default DiagnosisSection
