import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Empty, Select, Spin, Tag} from 'antd'
import {Color} from '../../style/custom/colors'
import {InputLabel} from './index'
import {InputLabelProps} from './InputLabel'
import {GenericSearch, OptionItem} from '../../utils/types'
import {observer} from 'mobx-react'
import {useServices} from '../../store'
import {compareObjectsByField, fromJson} from '../../utils/helper'
import {convertSearchResponse} from './utils/searchHelper'
import ErrorField, {ErrorFieldProps} from './ErrorField'
import {sharedStyles} from '../../style/shared_styles'
import {SearchTypes} from '../../utils/enums'
import {SearchOptions} from '../../service/utils/types'
import {useDefaultValueSet} from './utils/useDefaultValueSet'
import {useDebounce} from '../../utils/hooks/useDebounce'
import {CustomTagProps} from 'rc-select/lib/BaseSelect'

const {Option} = Select

/**
 * The custom component used for rendering a live search input.
 * The search is trigger after a custom number of characters and the search response will be displayed as a drop-down.
 * A label and an error validation is attached to the select input component
 */
const LiveSearch: React.FC<LiveSearchProps<any>> = observer(props => {
    useDefaultValueSet(props.defaultValue, props.onValueChanged, null, props.skipInitialize)

    const [value, setValue] = useState<string>('')
    const [searchValue, setSearchValue] = useState<string>('')
    const [options, setOptions] = useState<OptionItem[]>([])
    const [loadingData, setLoadingData] = useState(false)

    const {searchService} = useServices()

    useEffect(() => {
        const prefilledOptions: OptionItem[] = props.defaultValue ? convertSearchResponse([props.defaultValue], props.searchType) : []
        setOptions(prefilledOptions)
        setValue(prefilledOptions?.[0]?.value || '')
    }, [props.defaultValue])

    const triggerAfterNChar: number = useMemo(() => {
        return props.searchThreshold || 3
    }, [props.searchThreshold])

    const debounceDelay = useMemo<number>(() => {
        return props.debounceDelay || 400
    }, [props.debounceDelay])


    const getDataServer = async (currentSearchValue: string, searchType: SearchTypes, searchOptions?: SearchOptions) => {
        setLoadingData(true)
        const response: GenericSearch[] = await searchService.genericSearch(currentSearchValue, searchType, searchOptions)
        setOptions(convertSearchResponse(response, props.searchType))
        setLoadingData(false)
    }

    const debouncedSearch = useDebounce(getDataServer, debounceDelay)

    const tagRender = useCallback(
        (tagProps: CustomTagProps) => {
            const selectedOpt = options.find(el => el.value === tagProps.value)
            if (!selectedOpt) {
                if (props.onSearchValueChanged) {
                    const customOption = fromJson(tagProps.value as string)
                    return (
                        <Tag color={Color.active} style={{marginRight: 3}}>
                            {customOption.name}
                        </Tag>
                    )
                }
                return <React.Fragment />
            }
            return (
                <Tag color={Color.active} style={{marginRight: 3}}>
                    {selectedOpt.label}
                </Tag>
            )
        },
        [options]
    )

    useEffect(() => {
        if (!value) {
            return
        }
        const convertedValue = fromJson(value)
        if (compareObjectsByField(convertedValue, props.defaultValue, props.searchType === SearchTypes.icd10 ? 'code' : 'id')) {
            props.onValueChanged(convertedValue)
        }
    }, [value])

    useEffect(() => {
        if (searchValue.length >= triggerAfterNChar) {
            debouncedSearch(searchValue, props.searchType, props.searchOptions)
        }
    }, [searchValue])

    const onClear = () => {
        setValue('')
        setSearchValue('')
        setOptions([])
        props.onValueChanged('')
        props.onSearchValueChanged?.('')
    }

    const onBlur = () => {
        // basically to mark it as selected and disallow further searches until clear
        if (props.onSearchValueChanged && searchValue) {
            setValue(searchValue)
            props.onSearchValueChanged?.(searchValue)
        }
    }

    // dont show options if already have a have or not enough character inserted
    const showOptions = !value && searchValue.length >= triggerAfterNChar

    const inputStyle = props.error ? sharedStyles.border : {}

    const {id, ...labelProps} = props
    return (
        <div style={{maxWidth: props.maxWidth}}>
            <InputLabel {...labelProps} />
            <div style={{display: 'flex'}}>
                {
                    // show spinner like this when you can set any value in input not only from search result
                    loadingData && props.onSearchValueChanged &&
                    <div style={{...sharedStyles.center,  marginRight: 5}}>
                        <Spin size={"small"}/>
                    </div>
                }
                <Select
                    // fix wrong positioning on scroll
                    getPopupContainer={trigger => trigger.parentElement}
                    id={id}
                    // multiple mode allows using the search val as input val, switching to tags after first selection
                    mode={props.onSearchValueChanged && !value ? 'tags' : 'multiple'}
                    showSearch={true}
                    showArrow={false}
                    allowClear={true}
                    dropdownMatchSelectWidth={false}
                    defaultActiveFirstOption={true}
                    tagRender={tagRender}
                    onClear={onClear}
                    onBlur={onBlur}
                    placeholder={props.placeholder}
                    style={{...inputStyle, width: '100%'}}
                    value={value ? [value] : undefined}
                    onSelect={(val:string) => {
                        if (fromJson(val) === val) {
                            props.onSearchValueChanged?.(val)
                        }
                        setValue(val)
                        setSearchValue('')
                    }}
                    searchValue={searchValue}
                    notFoundContent={
                        searchValue.length >= triggerAfterNChar ? (
                            loadingData ? (
                                <div style={sharedStyles.center}>
                                    <Spin />
                                </div>
                            ) : (
                                <Empty />
                            )
                        ) : null
                    }
                    onSearch={val => {
                        if (!value) {
                            setSearchValue(val)
                        }
                    }}>
                    {showOptions &&
                    options.map(opt => (
                        <Option key={opt.key} value={opt.value}>
                            {opt.label}
                        </Option>
                    ))}
                </Select>
            </div>
            <ErrorField error={props.error} hideErrorField={props.hideErrorField} />
        </div>
    )
})

interface LiveSearchProps<T> extends InputLabelProps, ErrorFieldProps {
    options?: OptionItem[]
    onValueChanged: (value: T, error?: string) => void
    onSearchValueChanged?: (searchVal: string) => void
    searchThreshold?: number
    debounceDelay?: number
    searchType: SearchTypes
    /** If the search depends on some external data, use this prop to provide it; This assumes using ids as external data **/
    searchOptions?: SearchOptions
    defaultValue?: T
    placeholder?: string
    maxWidth?: number | string
    id?: string
}

export default LiveSearch
